import styled from "styled-components";

const nullProp = null;

export const Icon80x80Percent = styled.img`
  height: 80%;
  width: 80%;
`;

export const Icon40x40Percent = styled.img`
  height: 40%;
  width: 40%;
`;

export const RelativeIconBottom70Percent = styled.img`
position: absolute;
  top: -10px;
  right: -50px;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 1.8;

`;

export const Icon30x40pxPointer = styled.img`
  width: 30px;
  height: 40px;
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : "block")};
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Icon30x40pxPointerNoMargin = styled.img`
  width: 30px;
  height: 40px;
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : "block")};
  margin-top: auto;
  margin-bottom: auto;
`;

export const Icon35px100Perc = styled.img`
  height: 35px;
  width: 100%;
  cursor: pointer;
  @media (max-width: 1006px) {
    font-size: 100%;
  }
`;

export const Icon30x30 = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  padding: 2%;
  :hover {
    opacity: 70%;
    // width: 35px;
  }
  transition: opacity 0.5s;
`;

export const Icon15x15 = styled.img`
  height: 15px;
  width: 15px;
  cursor: pointer;
  display: ${(props) => (props.display ? props.display : "block")};
  padding: 2%;
  :hover {
    opacity: 70%;
    width: 35px;
  }
  transition: width 0.5s;
`;

export const Icon120x120 = styled.img`
  height: auto;
  width: 120px;
  transition: width 0.5s;
  @media (max-width: 1006px) {
    width: 35px;
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const Icon80x80 = styled.img`
  height: auto;
  width: 80px;
  transition: width 0.5s;
  @media (max-width: 1006px) {
    width: 35px;
  }
`;

export const Icon100x100 = styled.img`
  height: auto;
  width: 100px;
  transition: width 0.5s;
  @media (max-width: 1006px) {
    width: 35px;
  }
`;

export const Icon200x200 = styled.img`
  height:  ${(props) => (props.height ? props.height : "auto")};
  object-fit:  ${(props) => (props.objfit ? props.objfit : "")};
  margin:  ${(props) => (props.margin ? props.margin : "")};
  width: ${(props) => (props.width ? props.width : "225px")};
  transition: width 0.5s;
`;

export const Icon60x60 = styled.img`
  height: 60px;
  width: 60px;
  padding: 2%;
  zindex: ${(props) => (props.zindex ? props.zindex : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  :hover {
    opacity: 70%;
    width: 75px;
  }
  transition: width 0.5s;
  @media (max-width: 1006px) {
    width: 35px;
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const Icon45x45 = styled.img`
height:45px;
width:45px;
padding:2%;
:hover {
    opacity:70%;
}
display:${(props) => (props.display ? props.display : "block")};
transition: width 0.5s;
@media (max-width: 1006px) {
    margin-left:auto;
    height:35px;
`;

export const Icon20x20 = styled.img`
  width: 20px;
  padding: 2%;
  display: ${(props) => (props.display ? props.display : "block")};
  :hover {
    opacity: 70%;
  }
  transition: width 0.5s;
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export default nullProp;
