import styled from "styled-components";
import { animated } from "react-spring";
import { ColorPalette } from "../Colors";

const nullProp = null;

export const FlexColumnCont80vwAutoHeight = styled.div`
  width: 80vw;
  min-height: ${(props) => (props.minheight ? props.minheight : "100vh")};
  height: ${(props) => (props.height ? props.height : "")};
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 0vh;
  }
`;

export const QuoteItemCont = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: ${(props) => (props.margin ? props.margin : "0%")};
  align-items: ${(props) =>
    props.alignitems ? props.alignitems : "center"};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  opacity: ${(props) => (props.opacity ? props.opacity : "100%")};
  border-bottom: ${(props) =>
    props.borderbottom
      ? props.borderbottom
      : "1px solid" + ColorPalette.PrimaryGrey};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "95vw")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const OverflowDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 100%;
  min-height: auto;
  max-height: 550px;
  padding-bottom: 25px;
  margin-bottom: 25px;
`;

export const FlexColumnContHiddenMobileFlex = styled.div`
  width: ${(props) => (props.width ? props.width : "60%")};
  height: 100%;
  margin: 1%;
  align-items: left;
  display: ${(props) => (props.display ? props.display : "none")};
  flex-direction: column;
  justify-content: space-evenly;
  opacity: 100%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
  }
`;

export const FlexColumnCont95x30Percent = styled.div`
  width: 95%;
  height: 30%;
  margin: 1%;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 100%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const FlexColumnCont100xAutoPercent = styled.div`
  width: 100%;
  height:  ${(props) => (props.height ? props.height : "auto")};
  display: ${(props) => (props.display ? props.display : "flex")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  flex-direction: column;
  justify-content: center;
  opacity: 100%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const FlexRowContFullWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 100%;
  border: ${(props) => (props.border ? props.border : "")};
  &:hover {
    background-color: ${(props) => (props.hoverbgcolor ? props.hoverbgcolor : "")};
    margin: ${(props) => (props.hovermargin ? props.hovermargin : "")};
  }
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  transition: background-color, margin 0.3s;
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: flex;
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  opacity: 100%;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "90vw")};
    height: auto;
    flex-direction: ${(props) => (props.mobileflexdirection ? props.mobileflexdirection : "column")};
  }
`;

export const FlexColumnCont75x80Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "75%")};
  height: ${(props) => (props.height ? props.height : "80%")};
  margin: 1%;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 100%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const FlexColumnCont70x100Percent = styled.div`
  width: 70%;
  height: 100%;
  margin: 1%;
  text-align: center;
  align-items: center;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  justify-content: center;
  opacity: 100%;
  margin-top: 5%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const FlexColumnCont90x95Percent = styled.div`
  width: 90%;
  height: 95%;
  margin: 2%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 100%;
  @media (max-width: 1006px) {
    width: auto;
    height: auto;
  }
`;

export const AutoSizeOverflowY = styled.div`
  overflow-x: hidden;
  width: auto;
`;

export const FlexContDashboard = styled.div`
  width: 96%;
  background-color: white;
  border-radius: 25px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    box-shadow: none;
  }
`;

export const FlexContDashboardHeightFixed = styled.div`
  width: 92%;
  min-height: 14vh;
  padding: 2%;
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  max-height: 20vh;
  background-color: white;
  border-radius: 25px;
  box-shadow: ${(props) =>
    props.boxshadow
      ? props.boxshadow
      : "0px 4px 6px " + ColorPalette.PrimaryGrey};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    box-shadow: none;
  }
`;

export const FlexColumnOverflowCont800px = styled.div`
  max-height: 800px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 95%;
  margin: 1%;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const FlexListOverflowPropDiv = styled.div`
  max-height: ${(props) => (props.maxheight ? props.maxheight : "100%")};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const FlexColumnContAutoHeightLeftAlign = styled.div`
  width: 98%;
  height: auto;
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: left;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  opacity: ${(props) => (props.opacity ? props.opacity : "100%")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnCont90PercentLeftAlign = styled.div`
  width: 90%;
  margin: 1%;
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "10%")};
  height: auto;
  align-items: left;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  opacity: ${(props) => (props.opacity ? props.opacity : "100%")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    margin-left: 0%;
  }
`;

export const FlexColumnLeftAlign = styled.div`
  align-items: baseline
 width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export const FlexColumnLeftAlignMaxHeight = styled.div`
  align-items: baseline;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  justify-content: left;
  max-height: 25vh;
  width: 100%;
  overflow: auto;
`;

export const FlexColumnContGreyBorders = styled.div`
  width: auto;
  height: auto;
  min-width: 50vw;
  background-color: #eeeeee;
  margin: 2%;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-top: 5px;
`;

export const FlexColumnContGreenBorders = styled.div`
  width: auto;
  height: auto;
  min-width: 50vw;
  background-color: ${ColorPalette.LightGreen};
  margin: 2%;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-top: 5px;
`;

export const FlexColumnContRedBorderTopRounded = styled.div`
position: fixed;
left: 50%;
top: 30%;
transform: translateX(-50%);
width: 80%;
max-width: 500px;
background-color: white;
border-radius: 25px;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
padding: 30px;
overflow: hidden;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
transition: all 0.3s ease;

@media (max-width: 1006px) {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  border-radius: 0;
}
`;

export const FlexColumnContGreenBorderTopRounded = styled.div`
  position: fixed;
  left: 35%;
  top: 30%;
  width: 500px;
  display: ${(props) => (props.display ? props.display : "")};
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top: 20px solid ${ColorPalette.PrimaryButtonBlue};
  border-bottom: ${(props) => (props.border ? props.border : "2px solid grey")};
  border-left: ${(props) => (props.border ? props.border : "2px solid grey")};
  border-right: ${(props) => (props.border ? props.border : "2px solid grey")};
  height: auto;
  padding: 20px;
  @media (max-width: 1006px) {
    left: 0;
    width: auto;
    height: auto;
  }
`;

export const LeftItemWindowFixed = styled.div`
  position: fixed;
  overflow: auto;
  width: ${(props) => (props.width ? props.width : "")};
  max-height: ${(props) => (props.height ? props.height : "88vh")};
  height: ${(props) => (props.actualheight ? props.actualheight : "")};
  display: ${(props) => (props.display ? props.display : "")};
  left: ${(props) => (props.left ? props.left : "2%")};
  top: ${(props) => (props.top ? props.top : "2%")};
  min-width: ${(props) => (props.minwidth ? props.minwidth : "30vw")};
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top: 30px solid ${ColorPalette.PrimaryButtonBlue};
  padding: 20px;
  padding-top: 0px;
  @media (max-width: 1006px) {
    left: 0;
    width: auto;
    height: auto;
    z-Index: -1;
  }
`;

export const FlexColumnContGreenBorderTopRounded550pxHeight = styled.div`
  position: fixed;
  left: 30%;
  top: 10%;
  width: 550px;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top: 30px solid ${ColorPalette.PrimaryButtonBlue};
  height: ${(props) => (props.height ? props.height : "580px")};
  padding: 20px;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};

  @media (max-width: 1006px) {
    left: 4%;
    width: 80vw;
    height: 76vh;
    top: 5%;
  }
`;

export const FlexColumnCont250pxGreenBorderTopRounded = styled.div`
  position: fixed;
  left: 35%;
  top: 30%;
  width: 500px;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top: 30px solid ${ColorPalette.PrimaryButtonBlue};
  height: 250px;
  padding: 20px;
  @media (max-width: 1006px) {
    left: 0;
    width: auto;
    height: auto;
  }
`;

export const FlexRowCont85x15Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "85%")};
  height: ${(props) => (props.height ? props.height : "15%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnCont98xAutoFlexEnd = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: 1%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnContFullWidthOverflowY = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  margin: 1%;
  align-items: center;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-start;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnCont80x100Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "80%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "0%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont100x8PercentSpaceAround = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "8%")};
  margin: ${(props) => (props.margin ? props.margin : "0%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "-1%")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-around"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont55x15Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "55%")};
  height: ${(props) => (props.height ? props.height : "15%")};
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 1006px) {
    width: 90vw;
    height: auto;
  }
`;

export const FlexRowCont100x50Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "50%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowContAutox100Percent = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  flex-wrap: ${(props) => (props.flexwrap ? props.flexwrap : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;

export const FlexRowContAutox100PercentMobileColumn = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    flex-direction: column;
  }
`;

export const FlexColumnCont98x50PercentJustifyLeft = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "50%")};
  margin: 1%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnCont80x10PercentAlignLeft = styled.div`
  width: ${(props) => (props.width ? props.width : "80%")};
  height: ${(props) => (props.height ? props.height : "10%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnCont70x18PercentAlignLeft = styled.div`
  width: ${(props) => (props.width ? props.width : "70%")};
  height: ${(props) => (props.height ? props.height : "18%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont55x15PercentPropBorderBottom = styled.div`
  width: 90%;
  height: 100%;
  margin: 0;
  border-bottom: ${(props) => (props.borderbottom ? props.borderbottom : "")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowContPropWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "20%")};
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  word-wrap: break-word;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    justify-content: ${(props) =>
      props.mobilejustifycontent ? props.mobilejustifycontent : ""};
  }
`;

export const FlexRowContFlexStartAutoHeight = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowContFlexStart = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "20%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
    position: ${(props) =>
      props.position ? props.position : ""};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : "row"};
    margin-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : ""};
  }
`;

export const FlexRowContFlexStartMobileColumn = styled.div`
  width: 98%;
  height: ${(props) => (props.height ? props.height : "20%")};
  align-items: center;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    flex-direction: column;
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    text-align: left;
    align-items: baseline;
  }
`;

export const FlexRowCont70PercentSpaceBetween = styled.div`
  width: ${(props) => (props.width ? props.width : "70%")};
  height: 25px;
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "25px")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
  }
`;

export const FlexRowCont100PercentSpaceBetween = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "20")};
  margin: 0;
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
    background-color: ${(props) =>
      props.mobilebgcolor ? props.mobilebgcolor : ""};
  }
`;

export const FlexRowCont100PercentFlexEnd = styled.div`
  width: 98%;
  height: ${(props) => (props.height ? props.height : "20")};
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont70PercentFlexStart = styled.div`
  width: ${(props) => (props.width ? props.width : "70%")};
  height: 20;
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont60PercentSpaceEvenly = styled.div`
  width: ${(props) => (props.width ? props.width : "60%")};
  height: 20%;
  margin: 1%;
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  flex-wrap: ${(props) => (props.flexwrap ? props.flexwrap : "")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-evenly"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
  }
`;

export const FlexRowCont60PercentFlexStart = styled.div`
  width: 60%;
  height: 20%;
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: center;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont300x200 = styled.div`
  width: ${(props) => (props.width ? props.width : "300px")};
  height: ${(props) => (props.height ? props.height : "200px")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-evenly"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowCont = styled.div`
width:${(props) => (props.width ? props.width : "98%")};
height:${(props) => (props.height ? props.height : "20%")};
margin:${(props) => (props.margin ? props.margin : "1%")};
position:${(props) => (props.position ? props.position : "")};
top:${(props) => (props.top ? props.top : "")};
bottom:${(props) => (props.bottom ? props.bottom : "")};
left:${(props) => (props.left ? props.left : "")};
right:${(props) => (props.right ? props.right : "")};
border-bottom:${(props) => (props.borderbottom ? props.borderbottom : "")};
background-color:${(props) => (props.bgcolor ? props.bgcolor : "")};
align-items:${(props) => (props.alignitems ? props.alignitems : "center")};
display:${(props) => (props.display ? props.display : "flex")};
padding:${(props) => (props.padding ? props.padding : "")};
cursor:${(props) => (props.cursor ? props.cursor : "")};
margin-bottom:${(props) => (props.marginbottom ? props.marginbottom : "")};
margin-top:${(props) => (props.margintop ? props.margintop : "")};
border:${(props) => (props.border ? props.border : "")};
flex-direction:${(props) =>
  props.flexdirection ? props.flexdirection : "row"};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "center"};
margin-left:${(props) => (props.marginleft ? props.marginleft : "")};
border-radius${(props) => (props.borderradius ? props.borderradius : "")};
border-bottom:${(props) => (props.borderbottom ? props.borderbottom : "")};
overflow-y:${(props) => (props.overflowy ? props.overflowy : "")};
word-wrap:${(props) => (props.wordwrap ? props.wordwrap : "")};
z-Index:${(props) => (props.zIndex ? props.zIndex : "")};
@media (max-width: 1528px) {
  flex-direction:${(props) =>
    props.tabletflexdirection ? props.tabletflexdirection : ""};
  align-items:${(props) =>
    props.tabletalignitems ? props.tabletalignitems : ""};
  }
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  margin:${(props) => (props.mobilemargin ? props.mobilemargin : "")};
  border-bottom:${(props) =>
    props.mobileborderbottom ? props.mobileborderbottom : ""};
  background-color:${(props) =>
    props.mobilebgcolor ? props.mobilebgcolor : ""};
  align-items:${(props) =>
    props.mobilealignitems ? props.mobilealignitems : ""};
  display:${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  padding:${(props) => (props.mobilepadding ? props.mobilepadding : "")};
  padding-bottom:${(props) =>
    props.mobilepaddingbottom ? props.mobilepaddingbottom : ""};
  margin-bottom:${(props) =>
    props.mobilemarginbottom ? props.mobilemarginbottom : ""};
  border:${(props) => (props.mobileborder ? props.mobileborder : "")};
  flex-direction:${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
  justify-content:${(props) =>
    props.mobilejustifycontent ? props.mobilejustifycontent : ""};
  margin-left:${(props) =>
    props.mobilemarginleft ? props.mobilemarginleft : ""};
  overflow-y:${(props) => (props.mobileoverflowy ? props.mobileoverflowy : "")};
  flex-direction:${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
  position:${(props) => (props.mobileposition ? props.mobileposition : "")};
  top:${(props) => (props.mobiletop ? props.mobiletop : "")};
  bottom:${(props) => (props.mobilebottom ? props.mobilebottom : "")};
  left:${(props) => (props.mobileleft ? props.mobileleft : "")};
  right:${(props) => (props.mobileright ? props.mobileright : "")};
}
`;

export const FlexRowContHalfHeight = styled.div`
  width: 98%;
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-between"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowContHalfHeightFlexStart = styled.div`
  width: 98%;
  border-bottom: 0.5px solid ${ColorPalette.TertiaryGrey};
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexColumnContHalfHeightSpaceBetween = styled.div`
  width: 98%;
  height: 12%;
  border-bottom: 0.5px solid ${ColorPalette.TertiaryGrey};
  margin: ${(props) => (props.margin ? props.margin : "10px")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "baseline")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "space-between"};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const FlexRowContBoxShadow = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "15%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "row"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  box-shadow: 0px 5px 5px ${ColorPalette.PrimaryGrey};
  border-radius: 20px;
  padding: 2%;
  @media (max-width: 1006px) {
    flex-direction: column;
    width: 91vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }
`;

export const FlexContSpaceEvenly = styled.div`
  width: 98%;
  height: ${(props) => (props.height ? props.height : "20%")};
  margin: 1%;
  border-bottom: 1px solid ${ColorPalette.PrimaryGrey};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1006px) {
    justify-content: space-between;
  }
`;

export const RelativeFlexContAutoWidth13vh = styled.div`
width:auto;
height:13vh;
position:relative;
left 35%;
top:5%;
display:flex;
@media (max-width: 1006px) {
 height:80%;
 margin:1%;
 width:25vw;
 left:0;
 top:0;
 display:flex;
 justify-content:space-between;
}
`;

export const FlexContAutoWidth13vh = styled.div`
  width: auto;
  height: 13vh;
  background-color: white;
  display: flex;
`;

export const FlexContFullWidthHeightJustifyCenter = styled.div`
  width: auto;
  height: 100%;
  margin: 5%;
  display: flex;
  justify-content: center;
  justify-items: center;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const FlexContFullWidthJustifyCenter = styled.div`
  width: auto;
  margin: 5%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

export const FlexContzIndex1 = styled.div`
  display: flex;
  zindex: 1;
  @media (max-width: 1006px) {
    flex-direction: column;
  }
`;

export const FlexColumnContCenterAlign = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const FlexContCenterAlign = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  align-items: center;
  @media (max-width: 1006px) {
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
  }
`;

export const ContAutoWidth5vh = styled.div`
  height: 5vh;
  margin: 2%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  box-shadow: 0px 4px 8px #b7b7b7;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  @media (max-width: 1006px) {
    width: 88%;
    margin: 5%;
    height: 10vw;
    justify-content: flex-star;
  }
`;

export const Cont450pxAutoHeight = styled.div`
  width: 450px;
  height: auto;
  margin: 20px;
`;

export const GridCont1000px = styled.div`
  width: 1000px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px;
`;

export const Cont85vwAutoHeight = styled.div`
  width: 85vw;
  height: auto;
  margin: 1%;
  background-color: white;
`;

export const ContMargin20px = styled.div`
  margin: 20px;
`;

export const Cont400x575pxNoMargin = styled.div`
  width: 400px;
  height: 575px;
`;

export const Cont60PercentMargin20px = styled.div`
  width: 60%;
  margin: 20px;
`;

export const Cont100Percent100Percent = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContPadding10pxMargin20px = styled.div`
  background-color: white;
  border-radius: 4px;
  margin: 20px;
  border: 1px solid black;
  padding: 10px;
`;

export const FlexCont10vw15vh = styled.div`
  width: 150px;
  background-color: white;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 8px #b7b7b7;
  padding: 5px;
  height: 15vh;
`;

export const FlexCont300x150px = styled.div`
  width: 300px;
  height: 150px;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  margin: 10px;
  box-shadow: 0px 4px 8px #b7b7b7;
  padding: 10px;
  display: flex;
`;

export const FlexCont90PercentAutoHeight = styled.div`
  width: 90%;
  height: auto;
  margin: 2%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export const Cont100PercentMobile100Percent = styled.div`
  width: 100%;
  margin-bottom: 2%;
  @media (max-width: 1006px) {
    width: 100%;
  }
`;

export const Cont100Percent4Percent = styled.div`
  width: 100%;
  height: 4%;
  font-weight: bold;
`;

export const GreyCont100Percent4PercentDisplayProp = styled.div`
  width: 100%;
  height: auto;
  display: ${(props) => (props.displayProp ? props.displayProp : "block")};
  color: gray;
`;

export const Cont100Percent4PercentDisplayProp = styled.div`
  width: 100%;
  height: auto;
  display: ${(props) => (props.displayProp ? props.displayProp : "block")};
  font-weight: bold;
`;

export const Cont100PercentMobileOpt = styled.div`
  width: 100%;
  @media (max-width: 1006px) {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const RelativeCont100Percent = styled.div`
  width: 100%;
  margin: 1%;
  position: relative;
`;

export const Cont60x60 = styled(animated.div)`
  width: 60px;
  height: 60px;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
  margin: 30px;
  margin-top: 60px;
`;

export const FlexFixedCont = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  transform: "translate(-50%,-50%)";
  margin: 20px;
  zindex: 1000;
  display: flex;
`;

export const FixedContHeight120vh = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.7);
  zindex: 100;
`;

export const FlexCont70PercentAuto = styled.div`
  width: ${(props) => (props.width ? props.width : "70%")};
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px;
  @media (max-width: 1006px) {
    flex-direction: column;
    height: 150vh;
    width: 90%;
  }
`;

export const Cont180pxAutoHeight = styled.div`
  background-color: white;
  border-radius: 4px;
  margin: 5px;
  justify-content: space-around;
  min-width: auto;
  min-height: 50px;
  max-width: 100%;
  padding: 5px;
  border-bottom: 1px dashed ${ColorPalette.PrimaryGrey};
`;

export const Cont50Percent100Percent = styled.div`
  height: 100%;
  width: 50%;
`;

export const Cont50Percent100PercentFlexEnd = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;

export const Cont100Percent50Percent = styled.div`
  width: 100%;
  height: 50%;
`;

export const RelativeCont100vwPropBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  bottom: ${(props) => (props.contbottom ? props.contbottom : "74vw")};
  margin-left: 1vw;
`;

export const FlexCont80PercentAutoHeightPadding = styled.div`
  width: 80%;
  margin: 2%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3%;
  box-shadow: 0px 4px 8px #b7b7b7;
`;

export const Cont82vw21vh = styled.div`
  height: 21vh;
  width: ${(props) => (props.width ? props.width : "82vw")};
  display: ${(props) => (props.display ? props.display : "none")};
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 101vw;
    height: 6vh;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const Cont17vw0vh = styled(animated.div)`
  height: 0vw;
  width: 17vw;
  margin: 1%;
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 100vw;
    height: 60px;
    background-color: white;
    position: fixed;
    bottom: 0;
    margin: 0;
    z-index: 1000;
  }
`;

export const FlexCont_9Percent_AutoHeight = styled.div`
  margin: 2%;
  width: 9%;
  background-color: white;
  border-radius: 4px;
  display: ${(props) => (props.display ? props.display : "flex")};
}
  justify-content: space-evenly;
  align-items: center;
  padding: 2%;
  cursor: pointer;
  @media (max-width: 1006px) {
    width: 88%;
    margin: ${(props) => (props.mobilemargin ? props.mobilemargin : "5%")};
    height: 10vw;
    justify-content: flex-star;
  }
`;

export const FlexCont80PercentAutoHeight = styled.div`
  width: 80%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2%;
  @media (max-width: 1006px) {
    width: 85vw;
    margin: 0vw;
  }
`;

export const RelativeContBGColorWhite = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  @media (max-width: 1006px) {
  }
`;

export const RelativeCont30vw80vh = styled.div`
  margin: 10px;
  width: 30vw;
  height: 80vh;
  position: relative;
  left: 35%;
  top: 5%;
  overflow-y: auto;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  @media (max-width: 1006px) {
    height: 60%;
    width: 70vw;
    top: 10%;
    left: 10%;
    overflow-y: scroll;
  }
`;

export const RelativeContOverflowY = styled.div`
  align-items: center;
  width: 20vw;
  position: relative;
  left: 35%;
  top: 5%;
  height: 75vh;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin-right: 50px;
  margin-right: 20px;
  @media (max-width: 1006px) {
    height: 65vh;
    width: 70vw;
    top: 10%;
    left: 10%;
  }
`;

export const Cont185pxBoxShadowCenter = styled.div`
  align-items: center;
  width: 185px;
  background-color: white;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0px 4px 8px #b7b7b7;
  padding: 5px;
  padding-bottom: 10px;
`;

export const FlexCont76PercentMobile = styled.div`
  width: ${(props) => (props.width ? props.width : "76vw")};
  height: 15px;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px dotted #d3d3d3;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  transition: background-color 0.2s;
  transition-timing-function: linear;
  @media (max-width: 1006px) {
    width: 88%;
    margin: 5%;
    height: 10vw;
    justify-content: flex-start;
  }
`;

export const FlexZoomSpaceBetween = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  &:hover {
    transform: scale(1.011);
  }
  transition: transform 0.2s;
  transition-timing-function: linear;
`;

export const Cont82vw15vh = styled.div`
  height: 15vh;
  width: 82vw;
  background-color: white;
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 101vw;
    height: 6vh;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
`;

export const FlexColumnPropWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "98%")};
  min-width: ${(props) => (props.minwidth ? props.minwidth : "")};
  height: ${(props) => (props.height ? props.height : "20%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  text-align: ${(props) => (props.alignitems ? props.alignitems : "")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  word-wrap: break-word;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "none")};
  }
`;

export const FlexColumnFullWidth = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "")};
  min-height: ${(props) => (props.minheight ? props.minheight : "")};
  max-height: ${(props) => (props.maxheight ? props.maxheight : "")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  text-align: ${(props) => (props.textalign ? props.textalign : "")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  transition: visibility 0.5s;
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : ""};
  }
`;

export const DivWithDisplay = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  overflow-wrap: anywhere;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  border: ${(props) => (props.border ? props.border : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : "column"};
  }
`;

export const GridDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-auto-rows: 0.2fr;
  grid-gap: 2px;
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  overflow-wrap: anywhere;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  border: ${(props) => (props.border ? props.border : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : "column"};
  }
`;

export const Grid_300Div = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); 
  grid-auto-rows: 0.2fr;
  grid-gap: 2px;
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  overflow-wrap: anywhere;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "regular")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "")};
  border: ${(props) => (props.border ? props.border : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : "column"};
  }
`;

export const DivWithMarginPadding = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "1%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  overflow-wrap: anywhere;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
    border: ${(props) => (props.border ? props.border : "2px solid grey")};
    box-shadow: 0px 4px 8px #b7b7b7;
    @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "flex")};
    flex-direction: ${(props) =>
      props.flexdirection ? props.flexdirection : "column"};
    padding-bottom: ${(props) =>
      props.mobilepaddingbottom ? props.mobilepaddingbottom : ""};
  }
`;
export const FlexColumnFullWidthMargins = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  padding-left: 2%;
  padding-right: 2%;
  height: ${(props) => (props.height ? props.height : "")};
  min-height: ${(props) => (props.minheight ? props.minheight : "")};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "left")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "flex-start"};
  transition: visibility 0.5s;
  margin:  ${(props) => (props.margin ? props.margin : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  }
`;

export const Div70PerWidth7PerHeight = styled.div`
  width: 70%;
  height: 7%;
  margin: 30px;
  display: flex;
  align-items: center;
  margin-left: 2%;
  @media (max-width: 1006px) {
  }
`;

export const FlexCont80PercentMobileOpt = styled.div`
  width: 80%;
  display: flex;
  flex-flow: row row;
  gap: 5px;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Cont100Percent80PercentOverflowY = styled.div`
  height: 80%;
  width: 100%;
  overflow-y: ${(props) => (props.overflowy ? props.overflowy : "auto")};
  overflow-x: ${(props) => (props.overflowx ? props.overflowx : "")};
`;

export const Cont400x575px = styled.div`
  width: 400px;
  height: 575px;
  margin: 20px;
`;

export const Cont400x500px = styled.div`
  width: 400px;
  height: 500px;
`;

export const FlexCont5vhMobile88x5 = styled.div`
  height: 5vh;
  margin: 2%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  box-shadow: 0px 4px 8px #b7b7b7;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  @media (max-width: 1006px) {
    width: 88%;
    margin: 5%;
    height: 10vw;
    justify-content: flex-star;
  }
`;

export const Cont400x400px = styled.div`
  width: 400px;
  height: 400px;
`;

export const Cont400x400pxFixed = styled.div`
  width: 400px;
  height: 400px;
  position: fixed;
  top: 40%;
  left: 17%;
  transform: "translate(-50%,-50%)";
  margin: 20px;
  zindex: 1000;
`;

export const Cont500x850px = styled.div`
  width: 500px;
  height: 850px;
  margin: 20px;
`;

export const Cont500x200px = styled.div`
  width: 500px;
  height: 200px;
  background-color: white;
  border: 1px solid black;
`;

export const Cont500x200pxFixed = styled.div`
  width: 500px;
  height: 200px;
  background-color: white;
  border: 1px solid black;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 40%;
  @media (max-width: 1006px) {
    position: absolute;
    left: 5vw;
    right: 5vw;
    width: 90vw;
    height: 25vh;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Cont500x200pxFixedNoMobile = styled.div`
  width: 500px;
  height: 200px;
  background-color: white;
  border: 1px solid black;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 40%;
`;

export const StickyCont400x400px = styled.div`
  width: 400px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  overflow: auto;
  height: ${(props) => (props.height ? props.height : "700px")};
  transform: "translate(-50%,-50%)";
  zindex: 1000;
`;

export const ImageContGallery = styled.div`
  width: 70vw;
  height: 70vh;
  padding: 2%;
  display: flex;
  border-radius: 10px;
  margin: 30px;
  @media (max-width: 1006px) {
    height: 50vh;
    width: 90vw;
    padding: 0%;
    flex-direction: column;
    margin: 0px;
    margin-top: 20vh;
  }
`;

export const AbsoluteCont = styled.div`
  width: 80vw;
  position: relative;
  left: 10vw;
  top: 8vh;
  overflow: auto;
  height: 800px;
  border-radius: 10px;
  transform: "translate(-50%,-50%)";
  zindex: 1001;
  @media (max-width: 1006px) {
    top: 0vh;
    left: 9.25vw;
  }
`;

export const AbsoluteCalculatorCont = styled.div`
  width: 400px;
  position: relative;
  left: 315px;
  top: 0vh;
  overflow: auto;
  height: 800px;
  border-radius: 10px;
  transform: "translate(-50%,-50%)";
  zindex: 1001;
  @media (max-width: 1006px) {
    display:none;
  }
`;

export const FlexContMobileColumn = styled.div`
  display: flex;
  @media (max-width: 1006px) {
    flex-direction: column;
    height: 0vh;
  }
`;

export const FullWidthMobileCont100vw100vh = styled(animated.div)`
  margin-right: 0px;
  background-color: white;
  border-right: 1px dotted #d3d3d3;
  @media (max-width: 1006px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const FlexContMobileTopVH = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  display: flex;
  @media (max-width: 1006px) {
    flex-direction: column;
    height: 0vh;
    position: relative;
    top: 7vh;
  }
`;

export const FlexCont100vw100vh = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  @media (max-width: 1006px) {
    flex-direction: column;
  }
`;

export const FlexDiv = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  display: ${(props) => (props.displayProp ? props.displayProp : "flex")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  justify-content: ${(props) => (props.justifycontent ? props.justifycontent : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
  }
`;

export const FlexColumn81vwLightGrey = styled.div`
  width: ${(props) => (props.width ? props.width : "81vw")};
  min-height: ${(props) => (props.minheight ? props.minheight : "100vh")};
  height: ${(props) => (props.height ? props.height : "")};
  position:relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 99.5%;
    height: auto;
    position: relative;
    padding-bottom: 15vh;
  }
`;

export const LeftCont = styled(animated.div)`
  width: ${(props) => (props.width ? props.width : "18vw")};
  height: 100vh;
  display: ${(props) => (props.display ? props.display : "")};
  background-color: white;
  border-right: 1px dotted #d3d3d3;
  @media (max-width: 1006px) {
    width: 100vw;
    height: auto;
  }
`;

export const RightCont = styled.div`
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 99.5%;
    top: 15%;
    position: relative;
  }
`;

export default nullProp;
