import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AddQuoteTag,
  UpdateQuoteTag,
} from "../../../API_Functions/CaseQuote_Functions";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
  FlexRowCont100PercentFlexEnd,
} from "../../../StylesheetComps/Cont";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth } from "../../../StylesheetComps/Quote";
import {
  RegularTextBoldRed,
  SmallHeaderLeagueSpartanWidth100Percent,
} from "../../../StylesheetComps/Titles";

const TagWindow = ({ open, onClose, tagObj, quoteid, RefreshTaglist }) => {
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const Save = async (tag) => {
    setIsLoading(true);
    if (tag.Id === 0) await AddQuoteTag(tag);
    else await UpdateQuoteTag(tag);
    
    RefreshTaglist();
    onClose();
    setIsLoading(false);
  };
  

  if (!open) return null;
  else {
    return (
      <FixedOverlay200vh>
          {isLoading && (
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <FlexColumnContGreenBorderTopRounded550pxHeight height='200px'>
          <Cont100PercentWidth>
            <br></br>

            <FlexRowCont justifycontent="flex-start">
              <SmallHeaderLeagueSpartanWidth100Percent>
                Enter Tag:
              </SmallHeaderLeagueSpartanWidth100Percent>
              <TextInput300x50
                placeholder="Tag Name"
                defaultValue={tagObj.TagName === null ? "" : tagObj.TagName}
                inputheight="1%"
                inputmargintop="0%"
                onChange={(e) => {
                  setErrorText("");
                  tagObj.TagName = e.target.value;
                }}
              ></TextInput300x50>
            </FlexRowCont>

            <RegularTextBoldRed>{errorText}</RegularTextBoldRed>

            <br></br>
          </Cont100PercentWidth>

          <FlexRowCont100PercentFlexEnd>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </RegularResponsiveButton>
            <RegularResponsiveButton
              onClick={() => {
                tagObj.Quote_Id = quoteid;
                Save(tagObj);
              }}
            >
              {" "}
              Save
            </RegularResponsiveButton>
          </FlexRowCont100PercentFlexEnd>
        </FlexColumnContGreenBorderTopRounded550pxHeight>
      </FixedOverlay200vh>
    );
  }
};

TagWindow.defaultProps = {
  CloseWindow: () => {},
  ContinueRevising: () => {},
  newCreatedRevision: () => {},
  RefreshProjectUI: () => {},
};

export default TagWindow;
