import React from "react";
import styled from "styled-components";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { useHistory } from "react-router-dom";
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";
import { ColorPalette } from "../../StylesheetComps/Colors";
// import { MobileBodyText, MobileHeader } from '../Text';
import { FlexDiv } from "../../StylesheetComps/Cont";

// CardContainer with subtle elegance and perfect spacing
const CardContainer = styled.div`
  background-color: white;
  margin: 5px;
  padding: 20px;
  height: 50px;
  width: 88%;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: "San Francisco", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
`;

const InfoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.h1`
  font-size: 17px;
  font-weight: 600;
  font-family: "Avenir", sans-serif;
  color: #333;
  margin: 0;
`;

const MobileHeader = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #555;
  font-family: "Avenir", sans-serif;
  font-style: italic;
`;

const StatusButton = styled.button`
  background-color: ${({ bgcolor }) => bgcolor || "#333"};
  color: white;
  font-family: "San Francisco", "Helvetica Neue", sans-serif;
  padding: 10px 20px;
  width: 30%;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    opacity: 0.85;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.3);
  }
`;

const Icon = styled.img`
  background-color: #f0f0f5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const StaffCaseCardMobile = ({
  address,
  homeowner,
  status,
  phone,
  onClick,
}) => {
  const history = useHistory();

  let StatusText;
  let StatusColor;

  switch (status) {
    case CaseStatus.Drafted:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.Submitted:
      StatusText = "Submitted";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Accepted:
      StatusText = "Assigned";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Processed:
      StatusText = "Estimated";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Closed:
      StatusText = "Closed";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.PayPending:
      StatusText = "Pending";
      StatusColor = ColorPalette.PrimaryYellow;
      break;
    case CaseStatus.Construction:
      StatusText = "In-Progress";
      StatusColor = ColorPalette.PrimaryOrange;
      break;
    case CaseStatus.CancelRequested:
      StatusText = "Cancelling";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Canceled:
      StatusText = "Cancelled";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    default:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
  }

  return (
    <CardContainer onClick={onClick}>
      <InfoGroup>
        <HeaderGroup>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon src="/quote_icon_grey.svg"></Icon>
            <div style={{ width: "200px" }}>
              <CardHeader>{address}</CardHeader>
              <MobileHeader>{homeowner}</MobileHeader>
            </div>
          </div>
        </HeaderGroup>
        <StatusButton bgcolor={StatusColor}>{StatusText}</StatusButton>
      </InfoGroup>
    </CardContainer>
  );
};

StaffCaseCardMobile.defaultProps = {
  address: "No address provided",
  homeowner: "No homeowner provided",
};

export default StaffCaseCardMobile;
