import { Circle, GoogleMap, InfoWindow, LoadScript, Marker, OverlayView, Polygon, Polyline, Rectangle, GroundOverlay  } from '@react-google-maps/api';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ColorPalette } from "../../StylesheetComps/Colors";
import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from "styled-components";
import { AddQuote, DeleteQuoteItemByEstimator, GetCaseById, GetQuoteByIdForUserDisplay, GetQuoteItem, GetQuoteObject, UpdateQuoteItem } from '../../API_Functions/CaseQuote_Functions';
import { GetTaxesForMetro } from '../../API_Functions/Store_Functions';
import { SetHeaderAuthorization } from '../../API_Functions/Utility_Functions';
import { Stored_Values } from '../../Stored_Values';
import { RegularResponsiveButton } from '../../StylesheetComps/Components';
import { FlexDiv } from '../../StylesheetComps/Cont';
import { FlexColumnDiv, FlexDivSpaceAround } from '../../StylesheetComps/Div';
import { GreenNavigationButton, LandscapePara, TextAreaRegular } from '../../StylesheetComps/LandscapeForm';
import { Icon30px20pxPointer, Icon30px30pxPointer } from '../../StylesheetComps/Quote';
import { LeagueSpartanHeaderSmall, TinyTextItalics } from '../../StylesheetComps/Titles';
import { UserRole } from '../../StylesheetComps/UserRoles';
import AreaInputPopup from '../../sitemappercomps/accessPopup';
import AccessItemPopupWindow from '../../sitemappercomps/itempopup';
import ConfirmationDialog from '../Windows/ConfirmationDialog';
import GridOverlay from '../Windows/GridOverlay';
import ConfirmWindow from '../Windows/ConfirmWIndow';
import Slider from '../DraggableSlider';
import ViewProposalEstimatorView from '../../UserProjectPages/ViewProposalEstimate';
import { TextAreaAutoResize } from '../../StylesheetComps/LandscapeForm';

const CircleSelection = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  transition: opacity 0.5s ease;
  opacity: ${props => props.fade ? 1 : 0};
  pointer-events: none;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

const DeletePopupContainer = styled.div`
  position: fixed;
  bottom: ${({visible}) => visible ? '0' : '-100%'};
  left: -10;
  width: 300px;
  border-radius: 25px 25px 25px 25px;
  height: auto;
  background: #fff;
  padding: 20px;
  transition: bottom 0.3s ease-out;
  z-index: 1001;
  overflow: auto;
`;

const BottomSection = styled.div`
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 10vh; // 10% of the viewport height
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  z-index: 998; // This should be high enough to float above the map
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:30%;
`;

const ButtonSection = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: flex-start;
  width: ${(props) => (props.width ? props.width : "100px")};
`;

const TopRightIcon = styled.img`
  position: absolute;
  top: 10px;  // Adjust these values to fit your needs
  right: 50px;
  display: ${(props) => (props.display ? props.display : "block")};
  height: 40px; // Set icon height
  width: 40px; // Set icon width
  z-index: 1000; // This should be high enough to float above the map
`;

const DeleteTopRightIcon = styled.img`
  position: absolute;
  top: 10px;  // Adjust these values to fit your needs
  right: 10px;
  display: ${(props) => (props.display ? props.display : "block")};
  height: 40px; // Set icon height
  width: 40px; // Set icon width
  z-index: 999; // This should be high enough to float above the map
`;

const DownloadTopRightIcon = styled.img`
  position: absolute;
  top: 10px;  // Adjust these values to fit your needs
  right: 90px;
  display: ${(props) => (props.display ? props.display : "block")};
  height: 40px; // Set icon height
  width: 40px; // Set icon width
  z-index: 999; // This should be high enough to float above the map
`;

const ZoomImage = styled.img`
  position: absolute;
  top: 10px;
  width:50px;
  height:50px;
  left: 10px;
`;

const libraries = ["places", "geometry"]; // Define the libraries array outside of your component

const SiteMapper = ({contWidth, ScrollToItemOnEstimate, DeselectIcon, RefreshEstimate}) => {

  const [scale, setScale] = useState(1);
  const MemoizedViewProposalEstimatorView = React.memo(ViewProposalEstimatorView);
  const newScale = 1 / scale; // The inverse of the scale will decrease as scale increases

  const containerStyle = {
    width: window.$ISMOBILE === true ? '100%' : contWidth,
    height: '90vh',
    position: 'relative',
    top: `calc(50% - (45vh * ${scale} / 2))`,
    transform: `scale(${scale})`,
    transformOrigin: 'center'
  };

  const [isEstimateShown, setIsEstimateShown] = useState(false);
  const [eyeDisplay, setEyeDisplay] = useState('/white_eye.svg');

  const [showRelated, setShowRelated] = useState(false);
  const [projectInstallSubtotal, setProjectInstallSubtotal] = useState(0);
  const [Quote, setProposal] = useState({});
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [accessTotal, setAccessTotal] = useState(0);
  const [supplyTotal, setSupplyTotal] = useState({ Subtotal: 0, Tax: [], Total: 0 });
  const [installTotal, setInstallTotal] = useState({ Subtotal: 0, Tax: [], Total: 0 });
  const [projectSubtotal, setProjectSubtotal] = useState(0);
  const [estimateName, setEstimateName] = useState("");
  const params = useParams();
  const [storeTaxes, setStoreTaxes] = useState(false);
  const [estimateGST, setEstimateGST] = useState(0)
  const [estimatePST, setEstimatePST] = useState(0)
  const [projectGrandTotal, setProjectGrandTotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [Case, setCase] = useState({});
  const [homeownerName, setHomeownerName] = useState("");
  const [projectCaseNumber, setProjectCaseNumber] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [invoicefileMaterial, setInvoicefileMaterial] = useState("invoice_material.pdf");
  const [invoicefileInstall, setInvoicefileInstall] = useState("invoice_install.pdf");
  const [makeRevisionsButtonDisplay, setMakeRevisionsButtonDisplay] = useState("block");
  const [caseStatus, setCaseStatus] = useState(0);

  const [addNotes, setAddNotes] = useState(false);
  // const [itemNotes, setItemNotes] = useState("");
  // const [itemNoteId, setItemNoteId] = useState(0);
  const [itemNoteId, setItemNoteId] = useState(null); // Use state for itemNoteId
  const [itemNotes, setItemNotes] = useState(''); // Use state for itemNotes


  const [snappingOn, setSnappingOn] = useState(false);

  const GetProposalInformation = async (quoteid) => {
    const data = await GetQuoteByIdForUserDisplay(quoteid);
    if (!data || !data.quote) return;
  
    const { quote } = data;
  
    let removalsHeader7Subtotal = 0;
    let removalsHeader8Subtotal = 0;
  
    quote?.Removal?.Items.forEach((o) => {
      removalsHeader7Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
      removalsHeader8Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
    });

    let projectInstallSubtotal = 0;
  
  quote?.Tags?.forEach((t) => {
    const primaryItems = quote?.Material?.Items.filter(
      (item) => item.Tag_Id === t.Id && item.Parent_Id === 0
    );

    primaryItems.forEach((o) => {
      const installSub = showRelated === true ? o.LaborSubtotal : o.LaborSubtotalWithRelatedItems;
      projectInstallSubtotal += installSub;
    });
  });

  quote?.Material?.Items.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      const installSub = showRelated === true ? o.LaborSubtotal : o.LaborSubtotalWithRelatedItems;
      projectInstallSubtotal += installSub;
    }
  });

    setProjectInstallSubtotal(projectInstallSubtotal)
    setProposal(quote);
    setDeliveryTotal(quote?.Delivery?.Tools?.reduce((accumulator, tool) => accumulator + tool.Price, 0) || 0);
    setAccessTotal(quote?.AccessArea?.Items?.reduce((accumulator, items) => accumulator + items.Price, 0) || 0);
    // UpdateUIBasedOnStatus(quote);
  
    const materialSubtotal = quote?.Material?.Total?.Subtotal || 0;
    const deliverySubtotal = quote?.Delivery?.Total?.Subtotal || 0;
    const toolSubtotal = quote?.Tool?.Total?.Subtotal || 0;
    const accessAreaSubtotal = quote?.AccessArea?.Total?.Subtotal || 0;
    const installSubtotal = Number(projectInstallSubtotal) > Stored_Values.MIN_LABOR 
      ? Number(projectInstallSubtotal) 
      : Stored_Values?.MIN_LABOR;
  
    supplyTotal.Subtotal = (materialSubtotal + deliverySubtotal).toFixed(2);
    installTotal.Subtotal = (installSubtotal + toolSubtotal + removalsHeader7Subtotal + accessAreaSubtotal).toFixed(2);
    
    const subtotal = Number(supplyTotal.Subtotal) + Number(installTotal.Subtotal);
    setProjectSubtotal(subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  
    if (quote.Tittle) {
      setEstimateName(quote.Tittle);
    }
  
    const storeTaxArray = await GetTaxesForMetro(params.Id);
    setStoreTaxes(storeTaxArray);
  
    let gstTax = 0;
    let pstTax = 0;
  
    const taxitems = [
      { type: 'Material', subtotal: materialSubtotal },
      { type: 'Delivery', subtotal: deliverySubtotal },
      { type: 'Labor', subtotal: installSubtotal },
      { type: 'Removal', subtotal: removalsHeader7Subtotal },
      { type: 'AccessArea', subtotal: accessAreaSubtotal },
      { type: 'Tool', subtotal: toolSubtotal },
    ];

    
    const gst = storeTaxArray.find(tax => tax.TaxCode === 'Gst');
    // const pst = storeTaxArray.find(tax => tax.TaxCode === 'PST');
  
    if (gst) {
      taxitems.forEach((item) => {
        const { type, subtotal } = item;
    
        if (type === 'Material') {
          gstTax += (gst.Rate / 100) * subtotal;
          // pstTax += (pst.Rate / 100) * subtotal;
        } else {
          gstTax += (gst.Rate / 100) * subtotal;
        }
      });
    }
  
    gstTax = gstTax.toFixed(2);
    // pstTax = pstTax.toFixed(2);
  
    // setEstimatePST(pstTax);
    setEstimateGST(gstTax);

    const grandTotal = Number(gstTax) + Number(subtotal.toFixed(2));
    setProjectGrandTotal(grandTotal);
    setDeposit(grandTotal * 0.1);
  };

  const history = useHistory();

  const CreateEstimate = async (accessObj) => {
    const quoteobj = await GetQuoteObject(0)
    quoteobj.AccessArea.Obstruct = accessObj.obstruction;
    quoteobj.AccessArea.Width = accessObj.width;
    quoteobj.AccessArea.Length = accessObj.length;
    quoteobj.AccessArea.Elevation = accessObj.elevation;
    quoteobj.AccessArea.Case_Id = params.caseId
    quoteobj.Case_Id = params.caseId
    quoteobj.Tittle = `Site Visit Budget`
    AddNewQuote(quoteobj, params.caseId);
  };

    const GetNewQuoteProposalNumber = async (caseid) => {
    var data = await GetCaseById(caseid);
    if (data != null) {
      if (data.Case?.Quotes?.length > 0) {
        var proposal = 0;
        data.Case.Quotes.forEach((element) => {
          if (element.Proposal > proposal) proposal = element.Proposal;
        });
        proposal++;
        return proposal;
      } else return 0;
    } else return 0;
  };

  const AddNewQuote = async (quoteobj, caseid) => {
    var proposal = await GetNewQuoteProposalNumber(caseid);
    quoteobj.Proposal = proposal;
    var data = await AddQuote(quoteobj);
    if (data !== null) {
      setEstimateId(data.quote.Id);
      GetProposalInformation(data.quote.Id)
      for (let index = 0; index < shapes.length; index++) {
        AddShapeToDatabase(shapes[index], data.quote.Id)
      }
      history.push(`/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/${data.quote.Id}`)
    }
  };

  const GetProjectInformation = async (caseId) => {
    const data = await GetCaseById(caseId);
    const { Case } = data || {};
  
    if (!Case) return;
  
    const {
      Name,
      Tittle,
      CaseNumber,
      Street,
      City,
      CaseStatus,
    } = Case;

    setCase(Case)
  
    if (Name) setHomeownerName(Name);
    if (Tittle) setProjectName(Tittle);
    if (CaseNumber) setProjectCaseNumber(CaseNumber);
  
    if (Street && City) {
      const ext = `${Street}-${City}`;
      setInvoicefileInstall(`invoice_install_${ext}.pdf`);
      setInvoicefileMaterial(`invoice_material_${ext}.pdf`);
    }
  
    if (
      [CaseStatus.Closed, CaseStatus.Canceled, CaseStatus.CancelRequested, CaseStatus.Drafted]
      .includes(CaseStatus)
    ) {
      setMakeRevisionsButtonDisplay("none");
    }
    
    setCaseStatus(CaseStatus);
    geocodeAddress(`${Case.Street}, ${Case.City}`)
  };

  //Polygon Variables
  const [shapes, setShapes] = useState([]);
  const [temporaryShapesArray, setTemporaryShapesArray] = useState([]);
  const [center, setCenter] = useState({ lat: 49.2734, lng: 123.1038 });  // default coordinates
  const [polylines, setPolylines] = useState([]);
  const [drawnPolyline, setDrawnPolylines] = useState([]);
  const [selectedAccessShape, setSelectedAccessShape] = useState([]);

  const [currentColor, setCurrentColor] = useState();
  const [currentVertices, setCurrentVertices] = useState([]);
  const [obstructions, setObstructions] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [drawing, setDrawing] = useState(true);
  const [currentDistance, setCurrentDistance] = useState(0);
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);

  //Selecting on Map
  const [selectedShape, setSelectedShape] = useState(null);
  const [selectedShapeObject, setSelectedShapeObject] = useState(null);
  const [selectedObstruction, setSelectedObstruction] = useState(null);
  const [selectedUtility, setSelectedUtility] = useState(null);

  const [mousePosition, setMousePosition] = useState(null)
  const [targetPosition, setTargetPosition] = useState(center);
  const [map, setMap] = useState(null);

  //Select Color Variables
  const [showColorCircles, setShowColorCircles] = useState(true);
  const [fade, setFade] = useState(false);

  //Animation
  const [dashArray, setDashArray] = useState([10, 2]);
  const [isDragging, setIsDragging] = useState(false);

  //Text Markups
  const [textMarkups, setTextMarkups] = useState([]);
  const [selectedText, setSelectedText] = useState(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTextToolSelected, setIsTextToolSelected] = useState(false);

  const [apiKey, setApiKey] = useState('AIzaSyAwFxJFlGK9eIRsKjHCw34NzS6CCPM8AEc');

  const inputRef = useRef(null);

  //Plotting Variables
  const [shapePlotterOpen, setShapePlotterOpen] = useState(false);
  const [isPlottingToolSelected, setIsPlottingToolSelected] = useState(false);
  
  const [draggingPolygonPosition, setDraggingPolygonPosition] = useState(false);


  //Access Area Window
  const [accessWindowVisible, setAccessWindowVisible] = useState(params.quoteId == 0 ? true : false);
  const [showAccessItemWindow, setShowAccessItemWindow] = useState(false);

  
  const [accessObject, setAccessObject] = useState({
    length: 0,
    width: 0,
    elevation: 0,
    obstruction: false
  });
  const [accessAreaItems, setAccessAreaItems] = useState([]);
  const [itemCategory, setItemCategory] = useState(params.quoteId === '0' ? 'Access' : 'Removal');
  
  //Shape Data
  const [currentShapeData, setCurrentShapeData] = useState({});
  const [activeSquareFeet, setActiveSquareFeet] = useState(0);
  const [activePerimeter, setActivePerimeter] = useState(0);

  //Info Window for Polygon Info
  const [infoWindow, setInfoWindow] = useState({
    isOpen: false,
    position: null,
    content: null
  });
  const [deleteWindow, setDeleteWindow] = useState({isOpen: false, item: null});
  const [selectedIcon, setSelectedIcon] = useState('polygon');
  const [showText, setShowText] = useState(false);
  const [fadeText, setFadeText] = useState(false);

  const [estimateId, setEstimateId] = useState(0);

  //Obstruction Window Popup
  const [obstructionWindowPopup, setObstructionWindowPopup] = useState(false);

  //Save Confirmation
  const [showDialog, setShowDialog] = useState(false);

  const handleSave = () => {
    history.push(`/viewestimatestaff/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0`);
    setShowDialog(false); // Close the dialog after saving
  };

  const geocodeAddress = (address) => {
    // initialize the geocoder
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        const { lat, lng } = results[0].geometry.location;
        if (typeof lat === 'function' && typeof lng === 'function') {
          setCenter({ lat: lat(), lng: lng() });
        } else {
          alert("Failed to get valid coordinates for the location: " + address);
        }
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };

  const formattedDateTime = async () => {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('default', { month: 'short' });
    const year = now.getFullYear();
    let hour = now.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minute = now.getMinutes().toString().padStart(2, '0');
    return (`${day} ${month} ${year}, ${hour}:${minute} ${ampm}`);
  };

  

  
  // const downloadPDF = async () => {
  //   const newContainerStyle = {
  //     ...containerStyle,
  //     width: '180%',
  //     height: '120vh',
  //     transform: `scale(0.9)`
  //   };
  
  //   const mapContainer = document.getElementById('map');
  //   Object.assign(mapContainer.style, newContainerStyle);
  
  //   const timestamp = await formattedDateTime();
  //   const input = document.getElementById('map');
  
  //   html2canvas(input, {
  //     useCORS: true,
  //     logging: true,
  //     scale: 1,
  //   })
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  
  //       const pdf = new jsPDF('p', 'in', [8.5, 11]); // 'p' for portrait mode, 'in' for inches
  
  //       // Convert the dimensions from pixels to inches (1 inch = 72 pixels)
  //       const imgWidth = canvas.width / 72;
  //       const imgHeight = canvas.height / 72;
  
  //       pdf.addImage(
  //         imgData, 
  //         'PNG', 
  //         0, // x position
  //         0, // y position
  //         imgWidth, // width in inches
  //         imgHeight // height in inches
  //       );
  
  //       pdf.save(`${timestamp} On-Site Estimate.pdf`);
  //       // Revert styles back to original state
  //       Object.assign(mapContainer.style, containerStyle);
  //     })
  //     .catch((error) => {
  //       // console.error('Error generating PDF:', error);
  //     });
  // };

  const downloadPDF = async () => {
    const newContainerStyle = {
      ...containerStyle,
      width: '180%',
      height: '120vh',
      transform: `scale(0.9)`
    };
  
    const mapContainer = document.getElementById('map');
    Object.assign(mapContainer.style, newContainerStyle);
  
    const timestamp = await formattedDateTime();
    const input = document.getElementById('map');
  
    html2canvas(input, {
      useCORS: true,
      logging: true,
      scale: 1,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
  
        const pdf = new jsPDF('p', 'in', [8.5, 11]); // 'p' for portrait mode, 'in' for inches
  
        // Convert the dimensions from pixels to inches (1 inch = 72 pixels)
        const imgWidth = canvas.width / 72;
        const imgHeight = canvas.height / 72;
  
        pdf.addImage(
          imgData, 
          'PNG', 
          0, // x position
          0, // y position
          imgWidth, // width in inches
          imgHeight // height in inches
        );
  
        // Create a Blob from the PDF data and trigger the download
        const pdfBlob = pdf.output('blob');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${timestamp} On-Site Estimate.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
  
        // Revert styles back to original state
        Object.assign(mapContainer.style, containerStyle);
      })
      .catch((error) => {
        // console.error('Error generating PDF:', error);
      });
  };
  
  

  const [zoom, setZoom] = useState(21); // adjust initial zoom level as needed
  const mapRef = useRef();

  const zoomIn = () => {
    setScale(prevScale => prevScale + 0.5);
    setZoom(21);
  };
  

  const zoomOut = () => {
  if(scale === 1) {
    setScale(1)
  } else {
    setScale(prevScale => prevScale - 0.5);
  }
  setZoom(21);
  };

  // function addVertex() {
  //   if (!drawing && !isDrawingPolygon) return;

  //   const newVertex = targetPosition;
  //   setCurrentVertices(currentVertices => [...currentVertices, newVertex]);
  //   if (currentVertices.length > 0) {
  //     const lastVertex = currentVertices[currentVertices.length - 1];
  //     const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
  //       new window.google.maps.LatLng(lastVertex), 
  //       new window.google.maps.LatLng(newVertex)
  //     );
  //     const distanceInFeet = distanceInMeters * 3.28084; // Convert from meters to feet
  //     setCurrentDistance(distanceInFeet.toFixed(2));
  //     setPolylines(polylines => [...polylines, [lastVertex, newVertex]]);
  //   }
  // }

  function addVertex() {
    if (!drawing && !isDrawingPolygon) return;
  
    // Add the targetPosition directly without rounding lat/lng
    const newVertex = targetPosition;
    setCurrentVertices(currentVertices => [...currentVertices, newVertex]);
  
    if (currentVertices.length > 0) {
      const lastVertex = currentVertices[currentVertices.length - 1];
      const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lastVertex), 
        new window.google.maps.LatLng(newVertex)
      );
      const distanceInFeet = distanceInMeters * 3.28084; // Convert from meters to feet
  
      // Round the distance for display only
      const roundedDistanceInFeet = Math.round(distanceInFeet * 2) / 2;
      setCurrentDistance(roundedDistanceInFeet.toFixed(2));
  
      setPolylines(polylines => [...polylines, [lastVertex, newVertex]]);
    }
  }
  
  
  

  // function addVertex() {
  //   if (!drawing) return;
  
  //   const newVertex = targetPosition; // This should be updated based on mouse position
  
  //   if (currentVertices.length > 0) {
  //     const lastVertex = currentVertices[currentVertices.length - 1];
  
  //     // Calculate the angle between the last vertex and the new vertex
  //     let angle = Math.atan2(newVertex.lat - lastVertex.lat, newVertex.lng - lastVertex.lng) * (180 / Math.PI);
  //     angle = (angle + 360) % 360; // Normalize angle to be between 0 and 360
  
  //     // Snap angle to nearest 45 degrees
  //     const snappedAngle = Math.round(angle / 45) * 45;
  
  //     // Calculate distance for maintaining the length
  //     const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
  //       new window.google.maps.LatLng(lastVertex), 
  //       new window.google.maps.LatLng(newVertex)
  //     );
  
  //     // Calculate new vertex position based on snapped angle and distance
  //     const radians = snappedAngle * (Math.PI / 180);
  //     const newLat = lastVertex.lat + distanceInMeters * Math.sin(radians) / 111319.9; // Convert meters to degrees latitude
  //     const newLng = lastVertex.lng + distanceInMeters * Math.cos(radians) / (111319.9 * Math.cos(lastVertex.lat * (Math.PI / 180))); // Adjust for longitude
  
  //     const snappedVertex = { lat: newLat, lng: newLng };
  
  //     // Update your vertices and polylines state with the snapped vertex
  //     setCurrentVertices(currentVertices => [...currentVertices, snappedVertex]);
  //     setPolylines(polylines => [...polylines, [lastVertex, snappedVertex]]);
  
  //     // Update distance if needed
  //     const distanceInFeet = distanceInMeters * 3.28084; // Convert from meters to feet
  //     setCurrentDistance(distanceInFeet.toFixed(2));
  //   } else {
  //     // If it's the first vertex, just add it without snapping
  //     setCurrentVertices(currentVertices => [...currentVertices, newVertex]);
  //   }
  // }
  

  function updateDistance() {
    const newVertex = targetPosition;
    if (currentVertices.length > 0) {
      const lastVertex = currentVertices[currentVertices.length - 1];
      const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lastVertex), 
        new window.google.maps.LatLng(newVertex)
      );
      const distanceInFeet = distanceInMeters * 3.28084; // Convert from meters to feet
      setCurrentDistance(distanceInFeet.toFixed(2));
    }
}

  function cancelDrawing() {
    setCurrentVertices([]);
    setPolylines([]);  // clear the polylines state
    setDrawing(false);
  }

  function finishDrawing() {
    // Determine if user is drawing a polygon or polyline
    const dataCalculator = isDrawingPolygon ? calculatePolygonData : calculatePolylineData;
  
    const shapeData = dataCalculator(currentVertices);
    setCurrentShapeData(shapeData)
    if(shapeData?.area){
      setActiveSquareFeet(shapeData?.area)
    }
    if(shapeData?.perimeter){
      setActivePerimeter(shapeData?.perimeter)
    }
    setShapes([...shapes, { color: currentColor, vertices: currentVertices, data: shapeData, name: 'Shape Name' }]);
    setCurrentVertices([]);
    setPolylines([]);  // clear the polylines state
    setCurrentDistance(0); // Reset distance
  }

  function calculatePolygonData(vertices) {
    let distance = 0;
    let distances = [];

    for (let i = 0; i < vertices.length; i++) {
        const start = vertices[i];
        const end = vertices[i + 1] || vertices[0];
        const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(new window.google.maps.LatLng(start), new window.google.maps.LatLng(end));
        distance += segmentDistance;
        distances.push(segmentDistance);
    }

    const area = window.google.maps.geometry.spherical.computeArea(vertices.map(vertex => new window.google.maps.LatLng(vertex)));

    // Convert to feet and square feet
    distances = distances.map(d => d * 3.28084);
    distance = roundToNearestHalf(distance * 3.28084);
    const areaInSqFt = roundToNearestHalf(area * 10.7639);

    return {
        distances,
        perimeter: distance,
        area: areaInSqFt,
    };
}

  
function calculatePolylineData(vertices) {
  let distance = 0;
  let distances = [];

  for (let i = 0; i < vertices.length; i++) {
    const start = vertices[i];
    const end = vertices[i + 1]; // No need to close the polyline
    if (end) {
      const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(start), 
        new window.google.maps.LatLng(end)
      );
      distance += segmentDistance;
      distances.push(segmentDistance);
    }
  }
  // Convert to feet
  distances = distances.map(d => d * 3.28084);
  distance = roundToNearestHalf(distance * 3.28084);

  return {
    distances,
    perimeter: distance,
  };
}


  function addMarker(event) {
    if (currentColor === 'red') {
      setObstructions([...obstructions, { position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, name: 'Obstruction Name' }]);
    } else if (currentColor === 'blue') {
      setUtilities([...utilities, { position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, name: 'Utility Name' }]);
    }
  }

  function undoLastVertex() {
    setCurrentDistance(0); // Reset distance
    setCurrentVertices(currentVertices => {
      const newVertices = [...currentVertices];
      newVertices.pop();
  
      // pop polylines at the same time
      setPolylines(polylines => {
        const newPolylines = [...polylines];
        newPolylines.pop();
        return newPolylines;
      });
  
      return newVertices;
    });
  
    // If there is only one vertex left, update targetPosition to center of the map
    if (currentVertices.length === 1) {
      setTargetPosition(targetPosition => {
        const newTargetPosition = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
        return newTargetPosition;
      });
    }

    setCurrentVertices(currentVertices => {
      const newVertices = [...currentVertices];
      newVertices.pop();
  
      // pop polylines at the same time
      setPolylines(polylines => {
        const newPolylines = [...polylines];
        newPolylines.pop();
        return newPolylines;
      });
  
      return newVertices;
    });
  
    // If there is only one vertex left, update targetPosition to center of the map
    if (currentVertices.length === 1) {
      setTargetPosition(targetPosition => {
        const newTargetPosition = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
        return newTargetPosition;
      });
    }
  }
  
  useEffect(() => {
    if (currentVertices.length > 0) {
      const lastVertex = currentVertices[currentVertices.length - 1];
      setTargetPosition({ lat: lastVertex.lat, lng: lastVertex.lng });
    }
  }, [currentVertices]);
  
  function savePDF() {
    const input = document.getElementById('map');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save("download.pdf"); 
      });
  }

  function selectPolygon(index) {
    setSelectedShape(index);
    setSelectedObstruction(null);
    setSelectedUtility(null);
  }

  function selectObstruction(index) {
    setSelectedObstruction(index);
    setSelectedShape(null);
    setSelectedUtility(null);
  }

  function selectUtility(index) {
    setSelectedUtility(index);
    setSelectedShape(null);
    setSelectedObstruction(null);
  }

  function circleToggle(showColorCircles) {
    if(showColorCircles) {
      setFade(false);
      setShowColorCircles(false);
    } else {
      setFade(true);
      setShowColorCircles(true);
    }
  }

 function deleteEntity() {
    if (selectedShape !== null) {
      setShapes(shapes.filter((polygon, index) => index !== selectedShape));
      setSelectedShape(null);
    } else if (selectedObstruction !== null) {
      setObstructions(obstructions.filter((obstruction, index) => index !== selectedObstruction));
      setSelectedObstruction(null);
    } else if (selectedUtility !== null) {
      setUtilities(utilities.filter((utility, index) => index !== selectedUtility));
      setSelectedUtility(null);
    } else if (selectedText !== null) {
      setTextMarkups(textMarkups.filter((_, index) => index !== selectedText));
      setSelectedText(null);
  }
  DeleteShapeFromDatabase(selectedShapeObject.id)
  }

  const DeleteShapeFromDatabase = async (shapeindex) => {  
    const response = await axios.delete(`${window.$POLYGONURL}?id=eq.${shapeindex}`, {
      headers: {
        apikey: window.$POLYGONAPIKEY,
          authorization: window.$POLYGONAUTHKEY
      }
    })
    .then(response => {
      console.log('Data deleted successfully:', response.data);
    })
    .catch(error => {
      console.error('Error deleting data:', error);
    });
  }

//   const AddItemToPolygon = async (item) => {
//     if (shapes.length > 0) { // Checking if there is any shape drawn
//       const lastShapeIndex = shapes.length - 1; // Get the index of the last shape in the shapes array

//       // Create a copy of the shapes array
//       const updatedShapes = [...shapes];
      
//       // If the 'items' property doesn't exist on a polygon object yet, add it.
//       if (!updatedShapes[lastShapeIndex].hasOwnProperty('items')) {
//         updatedShapes[lastShapeIndex].items = [];
//       }

//       // Push the new item data into the 'items' property of the last shape
//       updatedShapes[lastShapeIndex].items.push({ id: item.Id, name: item.Name });
//       // Update the shapes state with the new array
//       setShapes(updatedShapes);
//   }
// }

const [itemAddedPopup, setItemAddedPopup] = useState(false)

const AddItemToPolygon = async (item) => {
  
  if (shapes.length > 0) { // Checking if there is any shape drawn
    // Determine the target shape's index: the selected shape if there is one, or the last shape otherwise
    const targetShapeIndex = selectedShape !== null ? selectedShape : shapes.length - 1;
    
    // Create a copy of the shapes array
    const updatedShapes = [...shapes];

    // If the 'items' property doesn't exist on the target polygon object yet, add it.
    if (!updatedShapes[targetShapeIndex].hasOwnProperty('items')) {
      updatedShapes[targetShapeIndex].items = [];
    }

    // Push the new item data into the 'items' property of the target shape
    updatedShapes[targetShapeIndex].items.push({ id: item.Id, name: item.Name });
    // Update the shapes state with the new array
    setShapes(updatedShapes);

    AddShapeToDatabase(updatedShapes[targetShapeIndex], params.quoteId)
    RefreshEstimate()
  }

  setItemAddedPopup(true)
};



// const AddShapeToDatabase = async (shapedata, quoteId) => {  
//   const shapeDataToSend = {
//     ...shapedata,
//     estimateId: quoteId, // Assuming params.quoteId is passed as quoteId
//     type: shapedata.data.area == 0 ? 'Polyline' : 'Polygon' // Example value for type
//   };
//   if(quoteId !== 0 && quoteId !== '0') {
//   const resp = await axios.post(`${window.$POLYGONURL}`, [shapeDataToSend], {
//     headers: {
//       apikey: window.$POLYGONAPIKEY,
//         authorization: window.$POLYGONAUTHKEY
//     }
//   })
//   .then(response => {
//     console.log('Data inserted successfully:', response.data);
//   })
//   .catch(error => {
//     console.error('Error inserting data:', error);
//   });
// }
// }

const axios = require('axios'); // Ensure axios is imported

const AddShapeToDatabase = async (shapeData, quoteId) => {
  const shapeDataToSend = {
    ...shapeData,
    estimateId: quoteId, // Assuming params.quoteId is passed as quoteId
    type: shapeData.data.area === 0 ? 'Polyline' : 'Polygon' // Determine type based on area
  };

  if (quoteId !== 0 && quoteId !== '0') {
    try {
      // Adjust the URL and data payload according to how your API can search for an existing polygon
      const response = await axios.get(`${window.$POLYGONURL}?data=eq.${encodeURIComponent(JSON.stringify(shapeDataToSend.data))}`, {
        headers: {
          apikey: window.$POLYGONAPIKEY,
          authorization: window.$POLYGONAUTHKEY
        }
      });

      if (response.status === 200 && response.data.length > 0) {
        // Assuming the response includes details about the found shape(s)
        console.log('Shape already exists, updating');
        const existingShapeId = response.data[0].id; // Example: grabbing the ID of the first match

        // Perform an update operation here using existingShapeId
        // The URL and payload might need to be adjusted based on your API's requirements
        const updateResponse = await axios.patch(`${window.$POLYGONURL}?id=eq.${existingShapeId}`, shapeDataToSend, {
          headers: {
            apikey: window.$POLYGONAPIKEY,
            authorization: window.$POLYGONAUTHKEY
          }
        });

        console.log('Shape updated successfully:', updateResponse.data);
      } else {
        // Shape does not exist, create a new one
        const createResponse = await axios.post(`${window.$POLYGONURL}`, [shapeDataToSend], {
          headers: {
            apikey: window.$POLYGONAPIKEY,
            authorization: window.$POLYGONAUTHKEY
          }
        });

        console.log('Shape created successfully:', createResponse.data);
      }
    } catch (error) {
      console.error('Error accessing the database:', error);
    }
  }
};


const AddPlottedShapeToDatabase = async (item, quoteId) => {
  const itemDataToSend = {
    ...item,
    estimateId: quoteId
  }
  if(quoteId !== 0 && quoteId !== '0'){
  const resp = await axios.post(`${window.$PLOTICONURL}`, [itemDataToSend], {
    headers: {
      apikey: window.$POLYGONAPIKEY,
        authorization: window.$POLYGONAUTHKEY
    }
  })
  .then(response => {
    console.log('Data inserted successfully:', response.data);
  })
  .catch(error => {
    console.error('Error inserting data:', error);
  });
}
}
  
  const handleMapClick = (event) => {
    // Get the click position
    const clickPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
  
    // If a markup is selected, do nothing
    if (selectedText !== null) {
      setSelectedText(null);
      return;
    }
  
    if (inputVisible) {
      if (inputValue) {
        // If the input is visible and there's a value, add the markup to the array and hide the input
        setTextMarkups((prevMarkups) =>
          prevMarkups.map((markup) =>
            markup.isEditing
              ? { ...markup, text: inputValue, isEditing: false, color: currentColor }
              : markup
          )
        );
        setInputVisible(false);
        setInputValue("");
      } else {
        // If the input is visible but there's no value, just hide the input
        setInputVisible(false);
      }
    } else if (!inputVisible) {
      // If the input is not visible, set the input to visible and add a new editing markup
      setTextMarkups((prevMarkups) => [
        ...prevMarkups.map(markup => ({ ...markup, isEditing: false })),
        { id: prevMarkups.length, position: clickPosition, isEditing: true, color: currentColor},
      ]);
      setInputVisible(true);
      inputRef.current && inputRef.current.focus();
    }
  };

//   const handlePolygonClick = (shape, index) => {
//     setSelectedShape(index);
//     setSelectedShapeObject(shape)
//     console.log(`Selected Shape: ${selectedShape}, Current Shape: ${shape}, Index: ${index}`)
//     console.log(shape)
//     if(selectedShape === index){
//       setInfoWindow({
//         isOpen: true,
//         position: shape.vertices[0], // assuming this is the position you want to show the info window
//         content: (
//           <div>
//             <FlexDiv>
//             <FlexDiv>
//             <Icon30px20pxPointer onClick={()=>{ 
//                       setShowAccessItemWindow(true);
//             }} src='/Green Button.svg'></Icon30px20pxPointer>
//             <TinyTextItalics>Add Items</TinyTextItalics>
//             </FlexDiv>
//             <FlexDiv>
//             <Icon30px20pxPointer onClick={()=>{ 
//                       setTemporaryShapesArray(shapes)
//                       setDraggingPolygonPosition(true);
//                       setInfoWindow({isOpen:false});
//                       calculateIconPosition(selectedShape)
//             }} src='/Drag_icon.svg'></Icon30px20pxPointer>
//             <TinyTextItalics>Move Polygon</TinyTextItalics>
//             </FlexDiv>
//             </FlexDiv>
//             {addNotes === true ? (
//     <>
//         <TinyTextItalics>Add Notes</TinyTextItalics>
//         <FlexDiv>
//         <TextAreaAutoResize mobilewidth="100%" />
//         <Icon30px20pxPointer src='/Green Button.svg'></Icon30px20pxPointer>
//         </FlexDiv>
//     </>
// ) : null}

//             {shape.items && shape.items.map((item, itemIndex) => (
//               <FlexDiv>
//               <p onClick={()=>{ScrollToItemOnEstimate(item, itemIndex)}} key={itemIndex}>{item.name}</p>
//               <Icon30px20pxPointer onClick={()=>{ 
//                       setAddNotes(true);
//             }} src='/Pencil.svg'></Icon30px20pxPointer>
//               <Icon30px20pxPointer onClick={() =>{ setDeleteWindow({isOpen: true, item}); setInfoWindow({isOpen:false})}} src="/DeleteIcon.svg"></Icon30px20pxPointer>
//               </FlexDiv>
//             ))}
//           </div>
//         )
//       });
//     }
//   }

const [loading, setLoading] = useState(false); // Track loading state

const handlePolygonClick = (shape, index) => {
  setSelectedShape(index);
  setSelectedShapeObject(shape);
  console.log(`Selected Shape: ${selectedShape}, Current Shape: ${shape}, Index: ${index}`);
  
  if (selectedShape === index) {
    setInfoWindow({
      isOpen: true,
      position: shape.vertices[0], // Assuming the first vertex is the desired position
      content: createInfoWindowContent(shape, index),
    });
  }
};

useEffect(() => {
  if (selectedShapeObject) {
    setInfoWindow(prev => ({
      ...prev,
      content: createInfoWindowContent(selectedShapeObject, selectedShape),
    }));
  }
}, [itemNoteId, itemNotes, addNotes]);

const createInfoWindowContent = (shape, index) => (
  <div key={index}>
    <FlexDiv>
      <FlexDiv>
        <Icon30px20pxPointer onClick={() => setShowAccessItemWindow(true)} src='/Green Button.svg' />
        <TinyTextItalics>Add Items</TinyTextItalics>
      </FlexDiv>
      <FlexDiv>
        <Icon30px20pxPointer
          onClick={() => {
            setTemporaryShapesArray(shapes);
            setDraggingPolygonPosition(true);
            setInfoWindow({ isOpen: false });
            calculateIconPosition(selectedShape);
          }}
          src='/Drag_icon.svg'
        />
        <TinyTextItalics>Move Polygon</TinyTextItalics>
      </FlexDiv>
    </FlexDiv>
    {addNotes && (
        <>
          <TinyTextItalics>Add Notes</TinyTextItalics>
          <FlexDiv>
            <TextAreaAutoResize
              value={itemNotes} // Controlled value for the text area
              onChange={(e) => setItemNotes(e.target.value)}
              mobilewidth="100%"
            />
            <Icon30px20pxPointer
              onClick={() => {handleNoteSubmit(); setInfoWindow({isOpen:false})}}
              src={loading ? '/loadinggifgreen.gif' : '/Green Button.svg'} // Dynamic icon src
            />
          </FlexDiv>
        </>
      )}
    {shape.items && shape.items.map((item, itemIndex) => (
      <FlexDiv key={itemIndex}>
        <p onClick={() => ScrollToItemOnEstimate(item, itemIndex)}>
          {item.name}
        </p>
        <Icon30px20pxPointer
          onClick={() => handleAddNoteClick(item.id)}
          src="/Pencil.svg"
        />
        <Icon30px20pxPointer onClick={() => setDeleteWindow({ isOpen: true, item })} src="/DeleteIcon.svg" />
      </FlexDiv>
    ))}
  </div>
);

const handleAddNoteClick = async (itemId) => {
  setItemNoteId(itemId); // Set the note ID for the selected item
  setAddNotes(true); // Show the notes input area

  try {
    const resp = await GetQuoteItem(itemId); // Fetch item details
    if (resp && resp.item) {
      setItemNotes(resp.item.Notes || ''); // Set existing note or clear if not available
    } else {
      setItemNotes(''); // Clear if no note exists
    }
  } catch (error) {
    console.error("Failed to retrieve item notes:", error);
    setItemNotes(''); // Clear in case of an error
  }
};

const handleNoteSubmit = async () => {
  if (itemNoteId && itemNotes) {
    setLoading(true); // Show loading GIF
    console.log("Submitting Note:", itemNoteId, itemNotes);

    await UpdateQuoteNote(itemNoteId, itemNotes);

    setLoading(false); // Hide loading GIF after submission
  } else {
    alert("Please provide a valid note ID and note content.");
    console.error("itemNoteId or itemNotes is not set:", itemNoteId, itemNotes);
  }
};


const UpdateQuoteNote = async (shapeid, notes) => {
  try {
    const resp = await GetQuoteItem(shapeid);
    if (resp) {
      let itemTemp = { ...resp.item, Notes: notes };
      const respUpdate = await UpdateQuoteItem(itemTemp);
      if (respUpdate) {
        setAddNotes(false); // Close the notes UI after updating
        console.log("Note updated successfully");
      }
    }
  } catch (error) {
    console.error("Failed to update note:", error);
  } finally {
    setLoading(false); // Ensure loading is false even if an error occurs
  }
};





  // const SaveUpdatedPolygonPosition = async (selectedShape) => {
  //   const shapeData = shapes[selectedShape]

  //   const shapeDataToSend = { 
  //     ...shapeData,
  //     estimateId: params.quoteId, // Assuming params.quoteId is passed as quoteId
  //     type: shapeData.data.area === 0 ? 'Polyline' : 'Polygon' // Determine type based on area
  //   };

  //   const resp = await axios.patch(`${window.$POLYGONURL}?id=eq.${shapeData.id}`, shapeDataToSend, {
  //     headers: {
  //       apikey: window.$POLYGONAPIKEY,
  //       authorization: window.$POLYGONAUTHKEY
  //     }
  //   });
  // }

  const SaveUpdatedPolygonPosition = async (selectedShapeIndex) => {
    // Validate input
    if (selectedShapeIndex < 0 || selectedShapeIndex >= shapes.length) {
      console.error("Selected shape index is out of bounds");
      return;
    }
    
    const shapeData = shapes[selectedShapeIndex];
  
    if (!shapeData || !shapeData.id) {
      console.error("Invalid shape data or missing ID");
      return;
    }
  
    const shapeDataToSend = {
      ...shapeData,
      estimateId: params.quoteId, // Ensure 'params' is defined and accessible
      type: shapeData.data && shapeData.data.area === 0 ? 'Polyline' : 'Polygon'
    };
  
    try {
      const response = await axios.patch(`${window.$POLYGONURL}?id=eq.${shapeData.id}`, shapeDataToSend, {
        headers: {
          apikey: window.$POLYGONAPIKEY,
          authorization: window.$POLYGONAUTHKEY
        }
      });
  
      // Log success or handle the response appropriately
      console.log("Polygon updated successfully:", response.data);
    } catch (error) {
      // Handle errors, such as network issues or server errors
      console.error("Error updating polygon position:", error);
    }

    setDraggingPolygonPosition(false)
  };
  

  

  const onMarkerDragEnd = (event) => {
  
    // Capture the new marker position from the drag event
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    
    // Log the new position
  
    // Update the icon's position state
    setIconPosition({ lat: newLat, lng: newLng });
  
    // Calculate the lat/lng difference for the movement
    const latDiff = newLat - shapes[selectedShape].vertices[0].lat;
    const lngDiff = newLng - shapes[selectedShape].vertices[0].lng;
  
    // Log the differences to verify correctness
  
    // Map over the shapes to update only the selected shape's vertices
    const updatedShapes = shapes.map((shape, index) => {
      if (index === selectedShape) {
        // Map over the vertices of the selected shape and update their positions
        const updatedVertices = shape.vertices.map(vertex => ({
          lat: vertex.lat + latDiff,
          lng: vertex.lng + lngDiff,
        }));
  
        // Log the original and updated vertices of the selected shape
  
        // Return a new shape object for the selected shape with updated vertices
        return { ...shape, vertices: updatedVertices };
      }
  
      // For shapes that are not selected, return them as is
      return shape;
    });
  
    // Log the shapes before and after the update for debugging

  
    // Set the updated shapes array into state
    setShapes(updatedShapes);
  };
  

  const calculateIconPosition = (shapeIndex) => {
    const shapeVertices = shapes[shapeIndex].vertices; // Corrected variable name
    const avgLat = shapeVertices.reduce((acc, curr) => acc + curr.lat, 0) / shapeVertices.length;
    const avgLng = shapeVertices.reduce((acc, curr) => acc + curr.lng, 0) / shapeVertices.length;
  
    // Adjust the latitude slightly above the shape
    const newPosition = { lat: avgLat + 0.00005, lng: avgLng + 0.00009 };
    setIconPosition(newPosition);

  };
  
  const [iconPosition, setIconPosition] = useState(null);

  const handlePolylineClick = (shape, index) => {
    setSelectedShape(index);
    if(shape?.items && selectedShape === index){
    setInfoWindow({
      isOpen: true,
      position: shape.vertices[0], // assuming this is the position you want to show the info window
      content: (
        <div>
          {shape.items && shape.items.map((item, itemIndex) => (
            <FlexDiv>
            <p key={itemIndex}>{item.name}</p>
            <Icon30px20pxPointer onClick={() =>{ setDeleteWindow({isOpen: true, item}); setInfoWindow({isOpen:false})}} src="/DeleteIcon.svg"></Icon30px20pxPointer>
            </FlexDiv>
          ))}
        </div>
      )
    });
  } else {
    // setAccessWindowVisible(true);
    // setIsDrawingPolygon(false); 
    // setIsTextToolSelected(false); 
    // setDrawing(true); 
    // setSelectedIcon('icon2');
    // setCurrentColor(ColorPalette.PrimaryYellow);
  }
  }

  const handleDelete = async (itemToDelete) => {
    try {
      // Delete the item from the database
      await DeleteQuoteItemByEstimator(itemToDelete.id);  // Replace with your function to delete the item
  
      // Create a copy of the shapes array
      let updatedShapes = [...shapes];
  
      // For each shape, filter out the item to delete from the 'items' array
      updatedShapes.forEach(shape => {
        if (shape.items) {
          shape.items = shape.items.filter(item => item.id !== itemToDelete.id);
        }
      });
  
      // If the selected polygon's items array is empty, remove the polygon from the array
      if (updatedShapes[selectedShape] && (!updatedShapes[selectedShape].items || updatedShapes[selectedShape].items.length === 0)) {
        updatedShapes.splice(selectedShape, 1);
      }

      DeleteShapeFromDatabase(selectedShapeObject.id)
  
      // Update the shapes state
      setShapes(updatedShapes);
  
      // Close the delete window
      setDeleteWindow({isOpen: false, item: null});
  
    } catch (error) {
    }
  };

const [length, setLength] = useState('');
const [width, setWidth] = useState('');
const [elevation, setElevation] = useState('');
const [typeToUpdate, setTypeToUpdate] = useState(null);

const SetAccessInformation = async (type) => {
  setCurrentColor(ColorPalette.PrimaryYellow)
  setTypeToUpdate(type); // set the type to update
  setAccessWindowVisible(false); // close the popup
}

const [isGridOpen, setIsGridOpen] = useState(false);

const handleGridClick = (itemCategory) => {
  let color;
  switch(itemCategory) {
    case 'Material':
      color = ColorPalette.PrimaryButtonBlue;
      break;
    case 'Removal':
      color = ColorPalette.PrimaryRed;
      break;
    case 'Access':
      color = ColorPalette.PrimaryYellow;
      break;
    case 'Open':
      color = ColorPalette.PrimaryOrange;
      break;
    case 'ShowEstimate':
      setIsEstimateShown(true);
      color = ColorPalette.PrimaryButtonBlue;
      break;
    case 'HideEstimate':
      setIsEstimateShown(false);
      color = ColorPalette.PrimaryButtonBlue;
      break;
    default:
      console.log('Invalid item category');
      return;
  }

  setCurrentColor(color);
  if(itemCategory !== 'ShowEstimate' && itemCategory !== 'HideEstimate') {
    setItemCategory(itemCategory);
  } else {
    color = ColorPalette.PrimaryRed
    setItemCategory('Removal')
  }
  setIsGridOpen(false)
};

  
const [updatingMap, setUpdatingMap] = useState(false);

  const GetShapeData = async (estimateid) => {
    const resp = await axios.get(`${window.$POLYGONURL}?estimateId=eq.${estimateid}`, {
      headers: {
        apikey: window.$POLYGONAPIKEY,
        authorization: window.$POLYGONAUTHKEY
      }
    })
    if(resp.status === 200){
      if(resp.data.length > 0){
        setShapes(resp.data)
        setUpdatingMap(true)
        setDrawing(true); 
        setIsDrawingPolygon(true); 
        setIsTextToolSelected(false); 
        setSelectedIcon('icon1'); 
        setShowColorCircles(true); 
        setCurrentColor(ColorPalette.PrimaryRed); 
        setItemCategory('Removal')
      } else {
        // setShapes(...shapes,
        //   resp.data
        //   )
        // setUpdatingMap(true)
        // setIsDrawingPolygon(true); 
        // setIsTextToolSelected(false); 
        // setDrawing(true); 
        // setSelectedIcon('icon1'); 
        // setShowColorCircles(true); 
        // setCurrentColor(ColorPalette.PrimaryButtonBlue); 
        // setItemCategory('Material')
      }
    }

    const plottingiconresp = await axios.get(`${window.$PLOTICONURL}?estimateId=eq.${estimateid}`, {
      headers: {
        apikey: window.$POLYGONAPIKEY,
        authorization: window.$POLYGONAUTHKEY
      }
    })
    if(plottingiconresp.status === 200){
      if(plottingiconresp.data.length > 0){
        setIcons(plottingiconresp.data)
      }
    }


    }

    useEffect(() => {
      if(params.quoteId !== 0) {
        GetShapeData(params.quoteId)
      }
    }, [params.quoteId]);

    useEffect(() => {
      if (selectedIcon) {
        setShowText(true);
        setFadeText(true);
        const timer = setTimeout(() => setFadeText(false), 1500); // Start fade-out after 1.5 seconds
        const hideTimer = setTimeout(() => setShowText(false), 2000); // Hide text after 2 seconds
        return () => {
          clearTimeout(timer);
          clearTimeout(hideTimer);
        };
      }
    }, [selectedIcon]);

    useEffect(() => {
      const lastShape = shapes[shapes.length - 1];
    
      if(lastShape && typeToUpdate) {
        switch (typeToUpdate) {
          case 'length':
            setLength(lastShape.data.perimeter);
            break;
          case 'width':
            setWidth(lastShape.data.perimeter);
            break;
          case 'elevation':
            setElevation(lastShape.data.perimeter);
            break;
          default:
            break;
        }
    
        // Reset typeToUpdate since we've just used it
        setTypeToUpdate(null);
        setAccessWindowVisible('flex')
      }
      RefreshEstimate()
    }, [shapes]); // add typeToUpdate to the dependency array
  
    useEffect(() => {
      let interval;
      if (selectedShape !== null && !isDragging) {
        interval = setInterval(() => {
          setDashArray(prevArray => {
            const newArray = [...prevArray];
            const first = newArray.shift();
            newArray.push(first);
            return newArray;
          });
        }, 100);
      }
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [selectedShape, isDragging]);
  
    useEffect(() => {
      if (inputVisible) {
        // Set focus on the input after it becomes visible
        const timer = setTimeout(() => {
          inputRef.current && inputRef.current.focus();
        }, 0);
        return () => clearTimeout(timer);
      }
    }, [inputVisible]);
  
    useEffect(() => {
      let userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if(userinfo?.Role && userinfo?.Role === UserRole.Manager ||
        userinfo?.Role === UserRole.Estimator) 
        {
          SetHeaderAuthorization();
          GetProjectInformation(params.caseId)
          if(params.quoteId !== 0){
            GetProposalInformation(params.quoteId)
          }
        } else {
          history.push('/')
        }
      
    }, []);
    
    const prevShapesLength = useRef(shapes.length);
    const roundToNearestHalf = (num) => Math.round(num * 2) / 2;

    useEffect(() => {
      if(!updatingMap){
        return;
      }
      if (shapes.length > prevShapesLength.current) {
        if (params.quoteId !== '0') {
          const lastShape = shapes[shapes.length - 1];
          if(shapes.length == 2){
            setShowAccessItemWindow(false);
          } else {
            setShowAccessItemWindow(true);

          }
        }
      }
  
      // Remember the current length for the next render
      prevShapesLength.current = shapes.length;
    }, [shapes]);

  useLayoutEffect(() => {
    if (inputVisible) {
      inputRef.current && inputRef.current.focus();
    }
  }, [inputVisible]);

  const [onShapeSelect, setOnShapeSelect] = useState('none')
  const [selectedColor, setSelectedColor] = useState('white');
  const [icons, setIcons] = useState([]);
  const [tempiconCount, setTempIconCount] = useState(0); // New state for counting icons
  const [iconCount, setIconCount] = useState(0); // New state for counting icons

  const [markerIconSize, setMarkerIconSize] = useState(0.00001); // New state for square icon size
  const [squareIconSize, setSquareIconSize] = useState(0.00001); // New state for square icon size
  const [circleIconSize, setCircleIconSize] = useState(1); // New state for square icon size
  const [triangleIconSize, setTriangleIconSize] = useState(0.00001); // New state for square icon size
  const [rectangleIconSize, setRectangleIconSize] = useState(0.00001); // New state for square icon size

  const handlePlottingClick = (targetPosition) => {
    const newIcon = {
      type: onShapeSelect, // e.g., 'circle', 'square'
      position: {
        lat: targetPosition.lat,
        lng: targetPosition.lng,
      },
      color: selectedColor, // assuming you have a color picker
      size: markerIconSize
      // additional properties based on the icon type
    };
    AddPlottedShapeToDatabase(newIcon, params.quoteId)
    setIcons(prevIcons => [...prevIcons, newIcon]);
    setTempIconCount(prevCount => prevCount + 1);
  };  

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      if (isEstimateShown === true) {
        document.documentElement.scrollTo({
          top: window.innerHeight,
          behavior: 'smooth'
        });
        document.body.scrollTo({
          top: window.innerHeight,
          behavior: 'smooth'
        });
      } else {
        document.documentElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        document.body.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }, 1200); // 500 milliseconds delay
  
    // Cleanup the timeout if the component is unmounted or isEstimateShown changes
    return () => clearTimeout(scrollTimeout);
  }, [isEstimateShown]);
  
  


  const handleFinishPlotting = () => {
    setIconCount(tempiconCount)
    
    // Reset icon count to 0
    setTempIconCount(0)
    setShowAccessItemWindow(true);
    setIsPlottingToolSelected(false)
    setIsDrawingPolygon(true);
    setDrawing(true)
    setSelectedIcon('icon1');
  };

  const ChangeSizeOfSelectedShapes = (value) => {
    setMarkerIconSize(value)
  }

  //Custom Map Testing

  const [overlayBounds, setOverlayBounds] = useState(null);
  const [overlayUrl, setOverlayUrl] = useState('');
  const [overlayMarkers, setOverlayMarkers] = useState([]);
  const [isOverlayMode, setIsOverlayMode] = useState(false);

  const onOverlayMapLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const onOverlayMapClick = (event) => {
    if (overlayMarkers.length < 2) {
      const newMarker = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      setOverlayMarkers((current) => [...current, newMarker]);

      if (overlayMarkers.length === 1) {
        const newBounds = {
          north: Math.max(newMarker.lat, overlayMarkers[0].lat),
          south: Math.min(newMarker.lat, overlayMarkers[0].lat),
          east: Math.max(newMarker.lng, overlayMarkers[0].lng),
          west: Math.min(newMarker.lng, overlayMarkers[0].lng),
        };
        setOverlayBounds(newBounds);
      }
    }
  };

  const onOverlayMarkerDragEnd = (event, index) => {
    const newOverlayMarkers = overlayMarkers.map((marker, i) =>
      i === index ? { lat: event.latLng.lat(), lng: event.latLng.lng() } : marker
    );
    setOverlayMarkers(newOverlayMarkers);
  
    if (newOverlayMarkers.length === 2) {
      const newBounds = {
        north: Math.max(newOverlayMarkers[0].lat, newOverlayMarkers[1].lat),
        south: Math.min(newOverlayMarkers[0].lat, newOverlayMarkers[1].lat),
        east: Math.max(newOverlayMarkers[0].lng, newOverlayMarkers[1].lng),
        west: Math.min(newOverlayMarkers[0].lng, newOverlayMarkers[1].lng),
      };
      setOverlayBounds(newBounds);
    }
  };

  useEffect(() => {
    if (overlayMarkers.length === 2) {
      const newBounds = {
        north: Math.max(overlayMarkers[0].lat, overlayMarkers[1].lat),
        south: Math.min(overlayMarkers[0].lat, overlayMarkers[1].lat),
        east: Math.max(overlayMarkers[0].lng, overlayMarkers[1].lng),
        west: Math.min(overlayMarkers[0].lng, overlayMarkers[1].lng),
      };
      setOverlayBounds(newBounds);
    }
  }, [overlayMarkers]);
  
  const handleDoneClick = () => {
    setIsOverlayMode(false);
  };

  return (


    
    // <div style={{overflowX:'hidden', overflowY:'hidden'}}>
    // <LoadScript
    // id="siteMap"
    //   googleMapsApiKey={apiKey}
    //   libraries={libraries}
    // >
    //   <GoogleMap
    //   scale={scale}
    //     id="map"
    //     onLoad={setMap}
    //     mapContainerStyle={containerStyle}
    //     center={center}
    //     options={{
    //       tilt:0,
    //       zoom:zoom,
    //       maxZoom:100,
    //       mapTypeId:'hybrid',
    //       zoomControl: false,
    //       mapTypeControl: false,
    //       scaleControl: false,
    //       streetViewControl: false,
    //       rotateControl: false,
    //       fullscreenControl: false,
    //       gestureHandling: 'greedy',

    //     }}
    //     onDragStart={() => setIsDragging(true)}
    //     onDragEnd={() => setIsDragging(false)}
    //     onCenterChanged={() => {
    //       setTargetPosition({ lat: map?.getCenter().lat(), lng: map?.getCenter().lng() });
    //       updateDistance();
    //     }}
    //     onClick={(event) => {
    //       if (isTextToolSelected) {
    //         handleMapClick(event); // Your text tool function
      
    //         // addVertex(event);
    //         // addMarker(event); // Your normal map functions
    //       }
    //     }}        
    //   > 
//     <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
//       <LoadScript id="siteMap" googleMapsApiKey={apiKey} libraries={libraries}>
//         <GoogleMap
//           scale={scale}
//           id="map"
//           onLoad={onOverlayMapLoad}
//           mapContainerStyle={containerStyle}
//           center={center}
//           zoom={zoom}
//           options={{
//             tilt: 0,
//             maxZoom: 100,
//             mapTypeId: 'satellite',
//             zoomControl: true,
//             mapTypeControl: false,
//             scaleControl: false,
//             streetViewControl: false,
//             rotateControl: false,
//             fullscreenControl: false,
//             gestureHandling: 'greedy',
//           }}
//           onClick={onOverlayMapClick}
//         >
//           {overlayMarkers.map((marker, index) => (
//             <Marker
//               key={index}
//               position={marker}
//               draggable={true}
//               onDragEnd={(event) => onOverlayMarkerDragEnd(event, index)}
//             />
//           ))}

// {overlayBounds && (
//   <GroundOverlay
//     key={JSON.stringify(overlayBounds)} // Force re-render when bounds change
//     url={overlayUrl}
//     bounds={overlayBounds}
//     opacity={0.5}
//   />
// )}

<div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
<LoadScript id="siteMap" googleMapsApiKey={apiKey} libraries={libraries}>
  <GoogleMap
    scale={scale}
    id="map"
    onLoad={onOverlayMapLoad}
    mapContainerStyle={containerStyle}
    center={center}
    zoom={zoom}
    options={{
      tilt: 0,
      maxZoom: 100,
      mapTypeId: 'hybrid', // Use the desired map type here
      zoomControl: !isOverlayMode,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'greedy',
    }}
    onClick={isOverlayMode ? onOverlayMapClick : (event) => {
      if (isTextToolSelected) {
        handleMapClick(event); // Your text tool function
        // addVertex(event);
        // addMarker(event); // Your normal map functions
      }
    }}
    onDragStart={() => setIsDragging(true)}
    onDragEnd={() => setIsDragging(false)}
    onCenterChanged={() => {
      if (map) {
        setTargetPosition({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
        updateDistance();
      }
    }}
  >
    {overlayMarkers.map((marker, index) => (
      <Marker
        key={index}
        position={marker}
        draggable={true}
        onDragEnd={(event) => onOverlayMarkerDragEnd(event, index)}
      />
    ))}

    {overlayBounds && (
      <GroundOverlay
        key={JSON.stringify(overlayBounds)} // Force re-render when bounds change
        url={overlayUrl}
        bounds={overlayBounds}
        opacity={1}
      />
    )}

<div id="centered-div" 
     style={{ 
         position: 'absolute', 
         left: '50%', 
         top: '50%', 
         transform: 'translate(-50%, -50%)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center'
     }}
>
        {/* {drawing && currentVertices.length > 0 && targetPosition && (
        <Polyline
            path={[currentVertices[currentVertices.length - 1], targetPosition]}
            options={{
                strokeColor: currentColor,
                strokeOpacity: 1,
                strokeWeight: 3,
            }}
        />
        
    )} */}

    {/* CURRENT DRAWING POSITIONING: */}

    {/* {drawing && currentVertices.length > 0 && targetPosition && (
  <>
    <Polyline
        path={[currentVertices[currentVertices.length - 1], targetPosition]}
        options={{
            strokeColor: currentColor,
            strokeOpacity: 1,
            strokeWeight: 3,
        }}
    />
    <OverlayView
      position={{
        lat: (currentVertices[currentVertices.length - 1].lat + targetPosition.lat) / 2,
        lng: (currentVertices[currentVertices.length - 1].lng + targetPosition.lng) / 2
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div style={{backgroundColor: 'white', padding: '5px'}}>
        {currentDistance} ft
      </div>
    </OverlayView>
  </>
)} */}

{/* begin */}

{/* {
  drawing && currentVertices.length > 0 && targetPosition && (
    snappingOn ? (() => {
      // Snapping logic only applies here
      const lastVertex = currentVertices[currentVertices.length - 1];
      let angle = Math.atan2(targetPosition.lat - lastVertex.lat, targetPosition.lng - lastVertex.lng) * (180 / Math.PI);
      angle = (angle + 360) % 360; // Normalize angle
      
      const snappedAngle = Math.round(angle / 45) * 45;
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lastVertex), 
        new window.google.maps.LatLng(targetPosition)
      );

      const radians = snappedAngle * (Math.PI / 180);
      const snappedLat = lastVertex.lat + (distance * Math.sin(radians) / 111319.9); // Convert distance to degrees
      const snappedLng = lastVertex.lng + (distance * Math.cos(radians) / (111319.9 * Math.cos(lastVertex.lat * (Math.PI / 180))));

      const finalTargetPosition = { lat: snappedLat, lng: snappedLng };
      const displayDistance = (distance * 3.28084).toFixed(2); // Convert meters to feet for display

      return (
        <>
          <Polyline
              path={[lastVertex, finalTargetPosition]}
              options={{
                  strokeColor: currentColor,
                  strokeOpacity: 1,
                  strokeWeight: 3,
              }}
          />
          <OverlayView
            position={{
              lat: (lastVertex.lat + finalTargetPosition.lat) / 2,
              lng: (lastVertex.lng + finalTargetPosition.lng) / 2
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={{backgroundColor: 'white', padding: '5px'}}>
              {displayDistance} ft
            </div>
          </OverlayView>
        </>
      );
    })() : (
      // Original behavior without snapping
      <>
        <Polyline
            path={[currentVertices[currentVertices.length - 1], targetPosition]}
            options={{
                strokeColor: currentColor,
                strokeOpacity: 1,
                strokeWeight: 3,
            }}
        />
        <OverlayView
          position={{
            lat: (currentVertices[currentVertices.length - 1].lat + targetPosition.lat) / 2,
            lng: (currentVertices[currentVertices.length - 1].lng + targetPosition.lng) / 2
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={{backgroundColor: 'white', padding: '5px'}}>
            {currentDistance} ft
          </div>
        </OverlayView>
      </>
    )
  )
} */}

{
  drawing && currentVertices.length > 0 && targetPosition && (
    snappingOn ? (() => {
      // Snapping logic only applies here
      const lastVertex = currentVertices[currentVertices.length - 1];
      let angle = Math.atan2(targetPosition.lat - lastVertex.lat, targetPosition.lng - lastVertex.lng) * (180 / Math.PI);
      angle = (angle + 360) % 360; // Normalize angle
      
      const snappedAngle = Math.round(angle / 45) * 45;
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lastVertex), 
        new window.google.maps.LatLng(targetPosition)
      );

      const radians = snappedAngle * (Math.PI / 180);
      const snappedLat = lastVertex.lat + (distance * Math.sin(radians) / 111319.9); // Convert distance to degrees
      const snappedLng = lastVertex.lng + (distance * Math.cos(radians) / (111319.9 * Math.cos(lastVertex.lat * (Math.PI / 180))));

      const finalTargetPosition = { lat: snappedLat, lng: snappedLng };
      const displayDistanceFeet = (distance * 3.28084).toFixed(2); // Convert meters to feet for display
      const roundedDisplayDistance = roundToNearestHalf(displayDistanceFeet);
      const displayDistance = roundedDisplayDistance.toFixed(1);


      return (
        <>
          <Polyline
              path={[lastVertex, finalTargetPosition]}
              options={{
                  strokeColor: currentColor,
                  strokeOpacity: 1,
                  strokeWeight: 3,
              }}
          />
          <OverlayView
            position={{
              lat: (lastVertex.lat + finalTargetPosition.lat) / 2,
              lng: (lastVertex.lng + finalTargetPosition.lng) / 2
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={{backgroundColor: 'white', padding: '5px'}}>
              {displayDistance} ft
            </div>
          </OverlayView>
        </>
      );
    })() : (
      // Original behavior without snapping
      <>
        <Polyline
            path={[currentVertices[currentVertices.length - 1], targetPosition]}
            options={{
                strokeColor: currentColor,
                strokeOpacity: 1,
                strokeWeight: 3,
            }}
        />
        <OverlayView
          position={{
            lat: (currentVertices[currentVertices.length - 1].lat + targetPosition.lat) / 2,
            lng: (currentVertices[currentVertices.length - 1].lng + targetPosition.lng) / 2
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={{backgroundColor: 'white', padding: '5px'}}>
            {roundToNearestHalf(currentDistance)} ft
          </div>
        </OverlayView>
      </>
    )
  )
}

{/* end */}

    

{/* <img src="/targetwhite.svg" 
     alt="target" 
     style={{
         overflowX:'visible', 
         overflowY:'visible', 
         width: `${newScale * 200}%`
     }}
/> */}

<>
        {(() => {
            switch (onShapeSelect) {
                case 'circle':
                    return (
                        <Circle
                            center={targetPosition}
                            radius={
                              ((markerIconSize - 0.000001) / (0.0001 - 0.000001)) * (10 - 0.1) + 0.1
                            }                            
                            options={{
                                fillColor: selectedColor,
                                fillOpacity: 1,
                                strokeOpacity: 0,
                            }}
                        />
                    );
                    case 'square':
                      // Calculate square vertices based on targetPosition and markerIconSize
                      // const squareCoords = [
                      //     { lat: targetPosition.lat + markerIconSize, lng: targetPosition.lng - markerIconSize },
                      //     { lat: targetPosition.lat + markerIconSize, lng: targetPosition.lng + markerIconSize },
                      //     { lat: targetPosition.lat - markerIconSize, lng: targetPosition.lng + markerIconSize },
                      //     { lat: targetPosition.lat - markerIconSize, lng: targetPosition.lng - markerIconSize },
                      // ];
                      return (
                        <Polygon
                        paths={[
                          { lat: targetPosition.lat + markerIconSize, lng: targetPosition.lng - markerIconSize * 1.5 },
                          { lat: targetPosition.lat + markerIconSize, lng: targetPosition.lng + markerIconSize * 1.5 },
                          { lat: targetPosition.lat - markerIconSize, lng: targetPosition.lng + markerIconSize * 1.5 },
                          { lat: targetPosition.lat - markerIconSize, lng: targetPosition.lng - markerIconSize * 1.5 },
                        ]}
                        options={{
                          fillColor: selectedColor,
                          fillOpacity: 1,
                          strokeColor: selectedColor,
                          strokeOpacity: 0,
                        }}
                      />
                      
                      
                      );
                case 'triangle':
                    // Calculate triangle vertices based on targetPosition and markerIconSize
                    const triangleCoords = [
                        { lat: targetPosition.lat, lng: targetPosition.lng - markerIconSize },
                        { lat: targetPosition.lat + markerIconSize, lng: targetPosition.lng },
                        { lat: targetPosition.lat, lng: targetPosition.lng + markerIconSize },
                    ];
                    return (
                        <Polygon
                            paths={triangleCoords}
                            options={{
                                fillColor: selectedColor,
                                fillOpacity: 1,
                                strokeColor: selectedColor,
                                strokeOpacity: 0,
                            }}
                        />
                    );
                case 'rectangle':
                    // Define rectangle bounds based on targetPosition and markerIconSize
                    const rectangleBounds = {
                        north: targetPosition.lat + markerIconSize,
                        south: targetPosition.lat - markerIconSize,
                        east: targetPosition.lng + (markerIconSize * 2),
                        west: targetPosition.lng - (markerIconSize * 2),
                    };
                    return (
                        <Rectangle
                            bounds={rectangleBounds}
                            options={{
                                fillColor: selectedColor,
                                fillOpacity: 1,
                                strokeWeight: 0,
                            }}
                        />
                    );
                    case 'line':
                      // Define the start and end points of the line based on targetPosition and markerIconSize
                      const linePath = [
                          { lat: targetPosition.lat, lng: targetPosition.lng - (markerIconSize * 2) },
                          { lat: targetPosition.lat, lng: targetPosition.lng + (markerIconSize * 2) },
                      ];
                      return (
                          <Polyline
                              path={linePath}
                              options={{
                                  strokeColor: selectedColor,
                                  strokeOpacity: 1,
                                  strokeWeight: 2, // You can adjust the weight to make the line thicker or thinner
                              }}
                          />
                      );                    
                default:
                    return (
                        <img
                            src="/targetwhite.svg"
                            alt="target"
                            style={{
                                overflowX: 'visible',
                                overflowY: 'visible',
                                width: `${newScale * 200}%`,
                            }}
                        />
                    );
            }
        })()}
    </>

</div>

{deleteWindow.isOpen && (
  <OverlayView
    zIndex={2000}
    position={shapes[selectedShape].vertices[0]}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >
    <DeletePopupContainer visible={deleteWindow.isOpen}>
      <LeagueSpartanHeaderSmall>
      Are you sure you want to delete this?
      </LeagueSpartanHeaderSmall>
      <br></br>
      <FlexDivSpaceAround>
      <RegularResponsiveButton bgcolor={ColorPalette.PrimaryRed} onClick={() => { handleDelete(deleteWindow.item); setDeleteWindow({isOpen: false, item: null}); RefreshEstimate();}}>Delete</RegularResponsiveButton>
      <RegularResponsiveButton bgcolor={ColorPalette.PrimaryGrey} onClick={() => setDeleteWindow({isOpen: false, item: null})}>Cancel</RegularResponsiveButton>
      </FlexDivSpaceAround>
    </DeletePopupContainer>
  </OverlayView>
)}

{textMarkups.map((markup, index) => {
  if (!isDragging) {
    return (
      <OverlayView
        position={markup.position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        onClick={e => e.stopPropagation()} // Stop click event propagation
      >
        {markup.isEditing ? (
          <input
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              color: markup.color,
              backgroundColor: 'transparent',
              border: `1px solid ${currentColor}`,
              fontSize: '16px'
            }}
          />
        ) : (
          <div 
          style={{
            backgroundColor: selectedText === index ? 'rgba(0, 0, 0, .6)' : 'rgba(0, 0, 0, .3)', // Use darker background if selected
            padding: '5px',
            borderRadius:'5px'
          }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedText(index);
          }}
        >
            <LeagueSpartanHeaderSmall color={markup.color}>{markup.text}</LeagueSpartanHeaderSmall>
          </div>
        )}
      </OverlayView>
    );
  }
})}

{icons.map((icon, index) => {
    switch (icon.type) {
      case 'circle':
        return (
          <Circle
            key={index}
            center={icon.position}
            radius={
              ((icon.size - 0.000001) / (0.0001 - 0.000001)) * (10 - 0.1) + 0.1
            }         
            options={{
              fillColor: icon.color,
              fillOpacity: 1,
              strokeOpacity: 0,
            }}
          />
        );
        case 'square':
  // Calculate square vertices
  const squareSide = icon.size; // This represents the 'radius', adjust as necessary
  const squareCoords = [
    { lat: icon.position.lat + squareSide, lng: icon.position.lng - squareSide * 1.5 },
    { lat: icon.position.lat + squareSide, lng: icon.position.lng + squareSide * 1.5 },
    { lat: icon.position.lat - squareSide, lng: icon.position.lng + squareSide * 1.5 },
    { lat: icon.position.lat - squareSide, lng: icon.position.lng - squareSide * 1.5 },
  ];
  return (
    <Polygon
      key={index}
      paths={squareCoords}
      options={{
        fillColor: icon.color,
        fillOpacity: 1,
        strokeColor: icon.color,
        strokeOpacity: 0,
      }}
    />
  );
        case 'triangle':
          const triangleCoords = [
            { lat: icon.position.lat, lng: icon.position.lng - icon.size },
            { lat: icon.position.lat + icon.size, lng: icon.position.lng },
            { lat: icon.position.lat, lng: icon.position.lng + icon.size },
          ];
          return (
            <Polygon
              key={index}
              paths={triangleCoords}
              options={{
                fillColor: icon.color,
                fillOpacity: 1,
                strokeColor: icon.color,
                strokeOpacity: 0,
              }}
            />
          );
        break;

      //   case 'hexagon':
      // // Assuming icon.position is the center and icon.size is the radius
      // const hexagonCoords = [
      //   {lat: icon.position.lat + icon.size, lng: icon.position.lng},
      //   {lat: icon.position.lat + icon.size * 0.5, lng: icon.position.lng - icon.size * Math.sqrt(3) / 2},
      //   {lat: icon.position.lat - icon.size * 0.5, lng: icon.position.lng - icon.size * Math.sqrt(3) / 2},
      //   {lat: icon.position.lat - icon.size, lng: icon.position.lng},
      //   {lat: icon.position.lat - icon.size * 0.5, lng: icon.position.lng + icon.size * Math.sqrt(3) / 2},
      //   {lat: icon.position.lat + icon.size * 0.5, lng: icon.position.lng + icon.size * Math.sqrt(3) / 2},
      // ];
      // return (
      //   <Polygon
      //     key={index}
      //     paths={hexagonCoords}
      //     options={{
      //       fillColor: icon.color,
      //       fillOpacity: 1,
      //       strokeColor: icon.color,
      //       strokeOpacity: 0,
      //     }}
      //   />
      // );
      //     break;
case 'rectangle':
  const rectangleBounds = {
    north: icon.position.lat + icon.size, // Adjust for actual size
    south: icon.position.lat - icon.size, // Adjust for actual size
    east: icon.position.lng + (icon.size * 2), // Adjust for actual size
    west: icon.position.lng - (icon.size * 2), // Adjust for actual size
  };
  return (
    <Rectangle
      key={index}
      bounds={rectangleBounds}
      options={{
        fillColor: icon.color,
        fillOpacity: 1,
        strokeWeight: 0,
      }}
    />
  );
        break;
        case 'line':
          // Define the start and end points of the line based on targetPosition and markerIconSize
          const linePath = [
              { lat: icon.position.lat, lng: icon.position.lng - (icon.size * 2) },
              { lat: icon.position.lat, lng: icon.position.lng + (icon.size * 2) },
          ];
          return (
              <Polyline
                  path={linePath}
                  options={{
                      strokeColor: icon.color,
                      strokeOpacity: 1,
                      strokeWeight: 2, // You can adjust the weight to make the line thicker or thinner
                  }}
              />
          );                    
        default:
        return null;
    }
  })}

{polylines.map((polyline, index) => {
  if (!isDragging) {
    return (
      <Polyline
        key={index}
        path={polyline}
        options={{
          strokeColor: currentColor,
          strokeOpacity: 1,
          strokeWeight: 3,
        }}
      />
    );
  }
})}

       {drawing && currentVertices.length > 1 && currentVertices.map((vertex, index) => {
    if (index < currentVertices.length - 1) {
      return (
        <Polyline
          key={index}
          path={[vertex, currentVertices[index + 1]]}
          options={{
            strokeColor: currentColor,
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      );
    }
    return null;
  })}
        {currentVertices.map((vertex, index) => (
    <Icon30px20pxPointer
      src='/1_Icon.svg'
      key={index}
      position={vertex}
    />
  ))}

{draggingPolygonPosition && (
        <Marker
          zIndex={9999}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={iconPosition}
          icon={{
            url: "/Drag_icon.svg",
            // Specify the size, if desired (optional)
            size: new window.google.maps.Size(32, 32),
            // Specify the origin, if desired (optional, useful if your icon is a sprite)
            origin: new window.google.maps.Point(0, 0),
            // Specify the anchor, which determines the point of the icon to correspond to the marker's location
            anchor: new window.google.maps.Point(16, 16),
          }}
      
        />
      )}

     {shapes.map((shape, index) => {
  if (!isDragging) {
    return (
      (shape.data.area !== undefined) ? (
        <Polygon
          key={index}
          paths={shape.vertices}
          onClick={() =>{ setSelectedShape(index);    
            const shapeData = shape.data;
            setCurrentShapeData(shape.data)
            if (shapeData?.area) {
              setActiveSquareFeet(shapeData?.area);
            }
            if (shapeData?.perimeter) {
              setActivePerimeter(shapeData?.perimeter);
            } handlePolygonClick(shape, index)}}
          // onMouseDown={() =>{ setSelectedShape(index); handlePolygonClick(shape, index)}}
          options={{
            fillColor: shape.color,
            fillOpacity: (selectedShape === index) ? 0.45 : 0.25, // Increase opacity when selected
            strokeColor:  shape.color, // Change stroke color when selected
            strokeOpacity: 1,
            strokeWeight: (selectedShape === index) ? 5 : 3, // Increase stroke weight when selected
          }}
        >
          <OverlayView
          position={{ lat: shape.vertices[0].lat - 0.001, lng: shape.vertices[0].lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={{backgroundColor: 'white', padding: '5px'}}>
              {shape.name}
            </div>
          </OverlayView>
        </Polygon>
      ) : (
        <Polyline
          key={index}
          path={shape.vertices}
          onClick={() => {setSelectedShape(index);    
            const shapeData = shape.data;
            setCurrentShapeData(shape.data)
            if (shapeData?.area) {
              setActiveSquareFeet(shapeData?.area);
            }
            if (shapeData?.perimeter) {
              setActivePerimeter(shapeData?.perimeter);
            } setSelectedAccessShape(shape)}}
          onMouseDown={() => { setSelectedShape(index); setSelectedAccessShape(shape); handlePolylineClick(shape, index) }}
          options={{
            strokeColor: shape.color, // Change stroke color when selected
            strokeOpacity: 1,
            strokeWeight: (selectedShape === index) ? 5 : 3, // Increase stroke weight when selected
          }}
        >
          <OverlayView
            position={shape.vertices[0]}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={{backgroundColor: 'white', padding: '5px'}}>
              {shape.name}
            </div>
          </OverlayView>
        </Polyline>
      )
    );
  }
})}


    {infoWindow.isOpen && (
        <InfoWindow
          position={infoWindow.position}
          onCloseClick={() => {setInfoWindow({ isOpen: false, position: null, content: null }); DeselectIcon()} }
        >
          {infoWindow.content}
        </InfoWindow>
      )}

        {obstructions.map((obstruction, index) => (
          <Circle
            key={index}
            center={obstruction.position}
            onClick={() => selectObstruction(index)}
            radius={1}
            options={{
              fillColor: 'red',
              fillOpacity: 1,
              strokeOpacity: 0,
            }}
          >
            <OverlayView
              position={obstruction.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{backgroundColor: 'white', padding: '5px'}}>
                {obstruction.name}
              </div>
            </OverlayView>
          </Circle>
        ))}
        {utilities.map((utility, index) => (
          <Circle
            key={index}
            center={utility.position}
            onClick={() => selectUtility(index)}
            radius={1}
            options={{
              fillColor: 'blue',
              fillOpacity: 1,
              strokeOpacity: 0,
            }}
          >
            <OverlayView
              position={utility.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{backgroundColor: 'white', padding: '5px'}}>
                {utility.name}
              </div>
            </OverlayView>
          </Circle>
        ))}


    
   
      </GoogleMap>
    </LoadScript>
    {/* <DownloadTopRightIcon onClick={()=>{downloadPDF()}} src="/DownloadIcon_White.svg" alt="My Icon" /> */}
    {/* <TopRightIcon onClick={()=>{undoLastVertex()}} display={currentVertices.length === 0 ? 'none' : 'block'} src="/undo_icon.svg" alt="My Icon" /> */}
    <DeleteTopRightIcon onClick={()=>{
      deleteEntity();
    if(selectedAccessShape.color === ColorPalette.PrimaryYellow && !selectedAccessShape.items && isTextToolSelected == false){
      handlePolylineClick(selectedAccessShape)
    } 
    }} src="/DeleteIcon.svg" alt="My Icon" />
     <div style={{display: isPlottingToolSelected === true ? 'block' : 'none'}}>
            <Slider margin='72px 20px 40px 67px' onValueChange={(value)=>{ChangeSizeOfSelectedShapes(value)}}></Slider>
            </div>
            {/* {draggingPolygonPosition && (
              <Icon30px30pxPointer src='/Drag_icon.svg'></Icon30px30pxPointer>
      )} */}
    <ZoomImage src='/zoomin.svg' style={{ left: '135px' }}  onClick={zoomIn}></ZoomImage>
    <Slider width='80px' left='-30px' onValueChange={(inputValue) => {
    const maxInputValue = 0.000001;
    const minInputValue = 0.000099;
    const range = maxInputValue - minInputValue;
    const step = range / 20; // Divide the input range into 20 steps

    // Calculate the step index (0 to 20) for the inputValue
    let stepIndex = Math.floor((inputValue - minInputValue) / step);

    // Ensure the stepIndex is within 0 to 20
    stepIndex = Math.max(0, Math.min(20, stepIndex));

    // Map the stepIndex to the output value (21 to 1)
    const outputValue = 21 - stepIndex;

    setScale(outputValue);
}} />
        <ZoomImage src='/zoomout.svg' onClick={zoomOut}></ZoomImage>
    <AreaInputPopup shapes={shapes} maplength={length} mapwidth={width} visible={accessWindowVisible} onClose={(type)=>{SetAccessInformation(type)}} FinishAccess={(accesslength, accesswidth, accesselevation, accessobstruction)=>{setAccessObject({length: accesslength, width: accesswidth, elevation:accesselevation, obstruction:accessobstruction});}} CloseWindow={()=>{setAccessWindowVisible(false); 
      CreateEstimate(accessObject); setIsDrawingPolygon(true); setUpdatingMap(true); setIsTextToolSelected(false); setDrawing(true); setSelectedIcon('icon1'); setShowColorCircles(true); setCurrentColor(ColorPalette.PrimaryRed); setItemCategory('Removal')}} CloseWindowNoAdd={()=>{setAccessWindowVisible(false);}}
      UsePhotoAsOverlay={(previewsrc)=>{setOverlayUrl(window.$IMGURL+ previewsrc); console.log(previewsrc)}}
      ></AreaInputPopup>
      <ConfirmWindow
      open={shapePlotterOpen}
      confirmtext={`To plot a shape on the map, select a shape and color, hover over the location, and click 'Place Plot'. Once done, hit 'Finish Plotting' to go back to the Item Addition screen.
      `}
      rightbuttontext={'Continue'}
      rightbuttoncolor={ColorPalette.PrimaryButtonBlue}
      leftbuttoncolor={ColorPalette.PrimaryRed}
      LeftFunction={()=>{setShapePlotterOpen(false);}}
      RightFunction={()=>{setShapePlotterOpen(false);
      }}
      leftbuttontext={'Back'}
      ></ConfirmWindow>

<ConfirmWindow
      open={itemAddedPopup}
      confirmtext={"Item added to estimate."}
      rightbuttontext={'Continue'}
      rightbuttoncolor={ColorPalette.PrimaryButtonBlue}
      leftbuttoncolor={ColorPalette.PrimaryRed}
      LeftFunction={()=>{setItemAddedPopup(false);}}
      RightFunction={()=>{setItemAddedPopup(false);
      }}
      leftbuttontext={'Back'}
      ></ConfirmWindow>

    <div style={{
  display: shapes.length >= 2 ? 'flex' : 'none',
  position: 'absolute',
  left: '10vw',
  bottom: '4%',
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none'
}}
>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: (window.$ISMOBILE === true ? '10px' : '15vw'), pointerEvents: 'auto' }}>
  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '210px', pointerEvents: 'auto', height: '55px' }}>
    <img onClick={()=>{setIsDrawingPolygon(true); setIsTextToolSelected(false); setIsPlottingToolSelected(false); setDrawing(true); setSelectedIcon('icon1');}} 
      style={{ height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon1' ? `4px solid ${currentColor}` : 'none'}} 
      src="/new_polygon_icon.svg" alt="icon1" />
          {selectedIcon === 'icon1' && showText && (
            <span style={{ position: 'absolute', color: 'white', width: '250px', left: '50px', top: '16px', transition: 'opacity 0.5s', opacity: fadeText ? 1 : 0 }}>
              Polygon Tool Selected
            </span>
          )}


    <img onClick={()=>{setIsDrawingPolygon(false); setIsTextToolSelected(false); setIsPlottingToolSelected(false); setDrawing(true); setSelectedIcon('icon2');}} 
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon2' ? `4px solid ${currentColor}` : 'none'}} 
      src="/new_polyline_icon.svg" alt="icon2" />
           {selectedIcon === 'icon2' && showText && (
            <span style={{ position: 'absolute', color: 'white', width: '250px', left: '50px', top: '69px', transition: 'opacity 0.5s', opacity: fadeText ? 1 : 0 }}>
              Polyline Tool Selected
            </span>
          )}

    {/* <img onClick={() => {setIsTextToolSelected(true); setIsPlottingToolSelected(false); setSelectedIcon('icon3');}} 
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon3' ? `4px solid ${currentColor}` : 'none'}} 
      src="/text_icon.svg" alt="icon3" /> */}

        <img onClick={() => { 
          if(isEstimateShown === true){
            setIsEstimateShown(false);
            setEyeDisplay('/white_eye_hidden.svg')
          } else {
            setIsEstimateShown(true);
            setEyeDisplay('/white_eye.svg');
          }
          setSelectedIcon('icon6');}} 
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon6' ? `4px solid ${currentColor}` : 'none'}} 
      src={eyeDisplay} alt="icon6" />
        {selectedIcon === 'icon6' && showText && (
            <span style={{ position: 'absolute', color: 'white', width: '250px', left: '50px', top: '115px', transition: 'opacity 0.5s', opacity: fadeText ? 1 : 0 }}>
              Estimate View Show/Hide
            </span>
          )}

<img onClick={() => {setSnappingOn(!snappingOn)}} 
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon3' ? `4px solid ${currentColor}` : 'none'}} 
      src={snappingOn === true ? '/ruler_icon_black.svg' : '/ruler_icon.svg'} alt="icon3" />
        {selectedIcon === 'icon3' && showText && (
            <span style={{ position: 'absolute', color: 'white', width: '250px', left: '50px', top: '100px', transition: 'opacity 0.5s', opacity: fadeText ? 1 : 0 }}>
              Ruler Tool Selected
            </span>
          )}


<img 
  onClick={()=>{setIsDrawingPolygon(false); setIsTextToolSelected(false); setDrawing(false); setIsPlottingToolSelected(true); setShowAccessItemWindow(true); setSelectedIcon('icon4');}} 
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon4' ? `4px solid ${currentColor}` : 'none'}} 
      src="/shape_icon.svg" alt="icon4" />
        {selectedIcon === 'icon4' && showText && (
            <span style={{ position: 'absolute', left: '50px', color: 'white', width: '250px', top: '213px', transition: 'opacity 0.5s', opacity: fadeText ? 1 : 0 }}>
              Shape Plotting Tool Selected
            </span>
          )}
  </div>
    <img onClick={() => {setIsGridOpen(true);}} style={{height: '40px', width: '40px'}} src="/cubeicon2.svg" alt="target" />
  </div>
  
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', height: '55px', pointerEvents: 'auto', marginTop:'auto' }}>
    {/* <GreenCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryButtonBlue); setItemCategory('Material');}} />
    <RedCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryRed); setItemCategory('Removal');}} />
    <YellowCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryYellow); setItemCategory('Access');}} />
    <OrangeCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryOrange); setItemCategory('Open');}} /> */}
    <ConfirmationDialog 
        show={showDialog} 
        onCancel={() => {setShowDialog(false)}}
        onConfirm={()=>{handleSave()}} // This will be called when "Save" is clicked
      />
  <GridOverlay closeWindow={()=>{setIsGridOpen(false)}} isOpen={isGridOpen} onGridClick={(category)=>{handleGridClick(category)}} showDialog={()=>{setIsGridOpen(false); setShowDialog(true)}}></GridOverlay>
  
  </div>
 
</div>

{/* RIGHT SIDE */}

<div style={{ display: isPlottingToolSelected === true ? 'flex' : 'none', position: 'absolute', left: '90vw', bottom: '22.5%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px', pointerEvents: 'auto' }}>
  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '115px', pointerEvents: 'auto', height: '55px' }}>
    <img  
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'circle' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/sitemap_circle.svg" alt="icon1" onClick={()=>{setOnShapeSelect('circle');}} />
    <img onClick={()=>{setOnShapeSelect('square');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'square' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/sitemap_square.svg" alt="icon2" />
    <img onClick={()=>{setOnShapeSelect('triangle');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'triangle' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/sitemap_triangle.svg" alt="icon3" />
        {/* <img onClick={()=>{setOnShapeSelect('hexagon');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: selectedIcon === 'icon3' ? `4px solid ${currentColor}` : 'none'}} 
      src="/sitemap_hexagon.svg" alt="icon4" /> */}
        <img onClick={()=>{setOnShapeSelect('rectangle');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'rectangle' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/sitemap_rectangle.svg" alt="icon5" />

    <img onClick={()=>{setOnShapeSelect('line');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'line' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/arrow_selector_icon.svg" alt="icon6" />
        {/* <img onClick={()=>{setOnShapeSelect('line');}}
      style={{height: '40px', width: '40px', margin: showColorCircles ? '5px' : '0px', visibility: showColorCircles ? 'visible' : 'hidden', border: onShapeSelect === 'icon3' ? `4px solid ${selectedColor}` : 'none'}} 
      src="/sitemap_line.svg" alt="icon6" /> */}
      <input
      type="color"
      onChange={(e) => setSelectedColor(e.target.value)}
      defaultValue={ColorPalette.PrimaryButtonBlue} // Default color
    />
  </div>
  </div>
  
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', height: '55px', pointerEvents: 'auto', marginTop:'auto' }}>
    {/* <GreenCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryButtonBlue); setItemCategory('Material');}} />
    <RedCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryRed); setItemCategory('Removal');}} />
    <YellowCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryYellow); setItemCategory('Access');}} />
    <OrangeCircle visible={showColorCircles} fade={fade} onClick={() => {setCurrentColor(ColorPalette.PrimaryOrange); setItemCategory('Open');}} /> */}
    <ConfirmationDialog 
        show={showDialog} 
        onCancel={() => {setShowDialog(false)}}
        onConfirm={()=>{handleSave()}} // This will be called when "Save" is clicked
      />
  {/* <GridOverlay isOpen={isGridOpen} onGridClick={(category)=>{handleGridClick(category)}} showDialog={()=>{setIsGridOpen(false); setShowDialog(true)}}></GridOverlay> */}
  </div>
 
</div>

<AccessItemPopupWindow 
plottingSelected={isPlottingToolSelected} 
iconCount={iconCount} 
plottingCallback={()=>{
setShowAccessItemWindow(false);  setIsPlottingToolSelected(true); setShapePlotterOpen(true);
}} 
shapeData={selectedShape !== null ? selectedShape : shapes[shapes.length - 1]} 
squareFeetSM={currentShapeData?.area} 
perimeterSM={currentShapeData?.perimeter} 
AddItemToPolygon={(item)=>{AddItemToPolygon(item);}} 
removalCallback={()=>{setItemCategory('Material'); 
setCurrentColor(ColorPalette.PrimaryButtonBlue)}} 
onClose={()=>{setShowAccessItemWindow(false); setOnShapeSelect(null)}} 
visible={showAccessItemWindow} 
itemCategory={itemCategory} 
onListItemClick={(product)=>{setAccessAreaItems(prevItems => [...prevItems, product]); setShowAccessItemWindow(false);
}}></AccessItemPopupWindow>
        <BottomSection>
      <TextSection>
        <TinyTextItalics>SqFt: {activeSquareFeet.toFixed(2)}</TinyTextItalics>
        <TinyTextItalics>Perimeter: {activePerimeter.toFixed(2)}</TinyTextItalics>
      
      </TextSection>

      <ButtonSection display={isPlottingToolSelected === true || draggingPolygonPosition === true ? 'none' : 'flex'}>
        <FlexColumnDiv>
        <FlexDiv>
        <RegularResponsiveButton display={isTextToolSelected === true ? 'none' : 'block'} mobiledisplay={isTextToolSelected === true ? 'none' : 'block'} bgcolor={ColorPalette.PrimaryGreen} onClick={()=>{ addVertex();
          }}>Add Point</RegularResponsiveButton>

<RegularResponsiveButton onClick={()=>{undoLastVertex()}} display={currentVertices.length === 0 ? 'none' : 'block'} bgcolor={ColorPalette.PrimaryRed}
          >Undo Last Point</RegularResponsiveButton>
          </FlexDiv>
          <FlexDiv>          
        <RegularResponsiveButton display={isTextToolSelected === true || currentVertices.length <= 0 ? 'none' : 'block'} mobiledisplay={isTextToolSelected === true ? 'none' : 'block'} bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{setSelectedShape(null); finishDrawing()}}>Finish Shape</RegularResponsiveButton>

        {isOverlayMode && (
        <RegularResponsiveButton onClick={handleDoneClick}>Done</RegularResponsiveButton>
      )}
       {!isOverlayMode && (
        <RegularResponsiveButton onClick={() => setIsOverlayMode(true)}>Load Custom Overlay</RegularResponsiveButton>
      )}
      </FlexDiv>
      </FlexColumnDiv>
      </ButtonSection>

      <ButtonSection display={isPlottingToolSelected === true ? 'flex' : 'none'}>
        <RegularResponsiveButton bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{handlePlottingClick(targetPosition);}}>Place Plot</RegularResponsiveButton>
        <RegularResponsiveButton  bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{handleFinishPlotting()}}>Finish Plotting</RegularResponsiveButton>
      </ButtonSection>

      <ButtonSection display={draggingPolygonPosition === true ? 'flex' : 'none'}>
      <RegularResponsiveButton   bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{
        setShapes(temporaryShapesArray)
        setDraggingPolygonPosition(false)}}>Cancel Move Polygon</RegularResponsiveButton>
        <RegularResponsiveButton   bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{SaveUpdatedPolygonPosition(selectedShape)}}>Save Polygon Position</RegularResponsiveButton>
      </ButtonSection>

    </BottomSection>
    {isEstimateShown && <ViewProposalEstimatorView />}
    </div>
  );
}

SiteMapper.defaultProps = {
  ScrollToItemOnEstimate:()=>{},
  DeselectIcon:()=>{},
  RefreshEstimate:()=>{},
}

export default SiteMapper;
