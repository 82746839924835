import styled from "styled-components";
import { ColorPalette } from "../Colors";

const nullProp = null;

export const AuthCont = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const LeftAuthCont = styled.div`
  width: 45vw;
  height: 100vh;
  background-image: url("${(props) =>
    props.backgroundimg ? props.backgroundimg : "/Log In Image.png"}");
  background-size: 100% 100px;
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const LeftItemsCont = styled.div`
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
`;

export const Header = styled.p`
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.headercolor ? props.headercolor : "black")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "3vw")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "5%")};
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "2.5%"};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  @media (max-width: 1006px) {
    font-size: 18px;
  }
`;

export const BodyPara = styled.p`
  font-family: Avenir;
  line-height: 25px;
  color: ${(props) => (props.bodycolor ? props.bodycolor : "black")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "1.25vw")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  text-align: ${(props) => (props.textalign ? props.textalign : "center")};
  width: ${(props) => (props.width ? props.width : "auto")};
  @media (max-width: 1006px) {
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "2.25vh"};
    width: 80%;
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;

export const RightAuthCont = styled.div`
  width: 55vw;
  height: 100vh;
  @media (max-width: 1006px) {
    width: 100vw;
    height: 100vh;
  }
`;

export const RightItemsCont = styled.div`
  width: 100%;
  height: 80%;
  position: relative;
  top: ${(props) => (props.top ? props.top : "7%")};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: height 2s;
`;

export const RightBarDiv = styled.div`
  width: 95%;
  height: ${(props) => (props.height ? props.height : "10%")};
  display: flex;
  justify-content: flex-end;
`;

export const LinkText = styled.a`
  font-family: LeagueSpartan-Bold;
  color: Black;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  margin: 3%;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${ColorPalette.PrimaryButtonBlue};
  }
  @media (max-width: 1006px) {
    font-size: 14px;
  }
`;

export const TextInput300x50 = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "300px")};
  height: ${(props) => (props.inputheight ? props.inputheight : "50px")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "4px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : ColorPalette.LightGrey};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  margin-top: ${(props) =>
    props.inputmargintop ? props.inputmargintop : "2%"};
  margin-left: ${(props) =>
    props.inputmarginleft ? props.inputmarginleft : "0%"};
  border: 0;
  padding: 18px;
  ::-webkit-file-upload-button {
    display: none;
  }
  &:focus {
    background-color: ${ColorPalette.LightGrey};
  }
  transition: background-color 0.1s;
  transition-timing-function: linear;
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    margin-left: ${(props) =>
      props.inputmarginleftmobile ? props.inputmarginleftmobile : "0%"};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "70vw")};
  }
`;

export const TextInputProp = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "200px")};
  height: ${(props) => (props.inputheight ? props.inputheight : "5%")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "4px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#F4F4F4"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin-right: 10px;
  margin-top: ${(props) =>
    props.inputmargintop ? props.inputmargintop : "0%"};
  border: 0;
  padding: 18px;
  ::-webkit-file-upload-button {
    display: none;
  }
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "10vw")};
    font-size: 12px;
  }
`;

export const SmallTextInput = styled.input`
  width: ${(props) => (props.width ? props.width : "80px")};
  height: "15px";
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: Avenir;
`;

export const RadioButtonInput100x30 = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "auto")};
  height: ${(props) => (props.inputheight ? props.inputheight : "30px")};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin: 10px;
  accent-color: green;
  margin-top: ${(props) =>
    props.inputmargintop ? props.inputmargintop : "2%"};
  border: 0;
  padding: 18px;
  ::-webkit-file-upload-button {
    display: none;
  }
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "8vw")};
  }
`;

export const RedirectDiv = styled.div`
  width: 45%;
  height: 5%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1006px) {
    width: 80vw;
  }
`;

export const InputDiv = styled.div`
  width: 47%;
  display: flex;
  @media (max-width: 1006px) {
    width: 77vw;
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : "row"};
  }
`;

export const GreenButtonWidth12VWHeight40 = styled.div`
  width: ${(props) => (props.width ? props.width : "12vw")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: white;
  vertical-align: middle;
  font-size: 1vw;
  margin: 2px;
  line-height: 2.5vw;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s;
  padding: 4px;
  display: ${(props) => (props.display ? props.display : "block")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    line-height: 4vh;
    height: 4vh;
  }
`;

export const LargeResponsiveButton = styled.div`
  width: ${(props) => (props.width ? props.width : "180px")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: white;
  vertical-align: middle;
  font-size: 1vw;
  margin: 2px;
  line-height: 2.5vw;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s;
  padding: 4px;
  display: ${(props) => (props.display ? props.display : "block")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    line-height: ${(props) => (props.mobilelineheight ? props.mobilelineheight : "4vh")};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "4vh")};
  }
`;

export const MediumGreenButton = styled.div`
  width: ${(props) => (props.width ? props.width : "20%")};
  height: ${(props) => (props.height ? props.height : "3vw")};
  text-align: center;
  box-shadow: 0px 8px 24px #d3d3d3;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "1.25vw")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "3vw")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "1%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border-radius: 10px;
  transition: 0.3s;
  display: ${(props) => (props.display ? props.display : "block")};
  &:hover {
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
    margin-top: 5vh;
    line-height: 6vh;
    height: 6vh;
  }
`;

export const MediumWhiteButton = styled.div`
  width: ${(props) => (props.width ? props.width : "30%")};
  height: ${(props) => (props.height ? props.height : "3vw")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "1.25vw")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "3vw")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "10%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border: ${(props) => (props.border ? props.border : "4px solid white")};
  border-radius: 10px;
  transition: 0.3s;
  display: ${(props) => (props.display ? props.display : "block")};
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "white")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ColorPalette.PrimaryButtonBlue};
    border: ${(props) =>
      props.hoverborder ? props.hoverborder : "4px solid #169ADB"};
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "")};
  }

  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "30vw")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
    margin-top: 5vh;
    line-height: 6vh;
    height: 6vh;
  }
`;

export const SelectableMediumLongButton = styled.div`
  width: ${(props) => (props.width ? props.width : "20%")};
  height: ${(props) => (props.height ? props.height : "3vw")};
  text-align: center;
  font-weight: bolder;
  font-family: Avenir;
  color: ${(props) => (props.color ? props.color : "black")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "1.25vw")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "3vw")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "10px")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "10px")};
  border: ${(props) => (props.border ? props.border : "4px solid white")};
  border-radius: 10px;
  transition: 0.3s;
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "90%")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
    margin-top: 5vh;
    line-height: 6vh;
    height: 6vh;
  }
`;

export const PrivacyPolicy = styled.p`
  font-family: Avenir;
  color: grey;
  font-size: 10px;
  @media (max-width: 1006px) {
    font-size: 12px;
    display: none;
  }
`;

export default nullProp;
